<template>
  <div :class="['Box__BgTitle mb-6', {'Box__BgTitle--NoBottom': noBottom}]">
    <svg height="16" width="16" class="fill-current Boxed">
      <use :xlink:href="`#icon-${icon}`"></use>
    </svg>
    <h2 class="text-lg">{{title}}</h2>
  </div>
</template>

<script>

export default {
  name: 'BgTitle',
  props: {
    title: String,
    icon: String,
    noBottom: Boolean
  }
}
</script>