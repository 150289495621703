<template>
  <div class="p-8">
   <h4 class="mb-4 TextTooltip">
    {{t(inviteUserType(userType))}}
      <button :content="t('pages.users.invite_user_hint')" v-tippy class="Btn--Tooltip">
        <svg height="18" width="18" class="fill-current"> 
          <use xlink:href="#icon-information-outline"></use>
        </svg>
      </button>
    </h4>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(inviteUser())">
        <ValidationProvider name="E-mail" rules="required|email" v-slot="{ errors }"  mode="lazy">
          <div class="field">
            <input v-model="user.email" type="email" placeholder="Email *" class="mb-2"  />
            <span class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
         <ValidationProvider :name="t('user_field.first_name')" rules="alpha_spaces" v-slot="{ errors }"  mode="lazy">
          <div class="field">
            <input v-model="user_fields.first_name" type="text" :placeholder="t('user_field.first_name')" class="mb-2" />
            <span class="text-danger">{{ errors[0] }}</span>
          </div>
         </ValidationProvider>
         <ValidationProvider :name="t('user_field.last_name')" rules="alpha_spaces" v-slot="{ errors }"  mode="lazy">
          <div class="field">
            <input v-model="user_fields.last_name" type="text" :placeholder="t('user_field.last_name')" class="mb-2" />
            <span class="text-danger">{{ errors[0] }}</span>
          </div>
         </ValidationProvider>
        <ValidationProvider :name="t('user_field.fiscal_code')" rules="alpha_num" v-slot="{ errors }"  mode="lazy">
          <div class="field" v-if="fiscalCodeLogin">
            <input v-model="user.fiscal_code" type="text" :placeholder="t('user_field.fiscal_code')" class="mb-2" />
             <span class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <button type="submit" ref="submit" class="Btn Btn--Save js-button">{{t('actions.save')}}</button>
      </form>
    </ValidationObserver>
     <modal name="upgradeModal">
       <upgradeModal feature="users" />
     </modal>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '../../api'
import { global as EventBus } from '../../js/utils/eventBus'; 
import upgradeModal from '../commons/upgradeModal';


export default {
  components: {
    upgradeModal
  },
  props: {
    currentUser: Object,
    userType: String,
    course: Object,
    fiscalCodeLogin: Boolean
  },
  data(){
    return {
      user: {
        email: '',
        fiscal_code: ''
      },
      user_fields: {
        first_name: '',
        last_name: ''
      },
    }
  },
  methods: {
    onSubmit () {
      alert('Form has been submitted!');
    },
  
    ...mapActions({
      addUser: 'addUser',
      addEnroll: 'addEnroll'
    }),

    inviteUserType(type) {
      switch (type) {
        case "super_admin":
          return 'pages.users.invite_super_admin'
        case "admin":
          return 'pages.users.invite_admin'
        case "coach":
          return 'pages.users.invite_coach'
        case "inspector":
          return 'pages.users.invite_inspector'
        default:
          return 'pages.users.invite_user'
      }
    },

    inviteUser() {
      const target = this.$refs.submit
      if (this.currentUser.users_limit_reached && !!this.user.email) {
         this.$modal.show('upgradeModal');
      } else if (!!this.user.email) {
        EventBus.emit('loader:local:start', { elem: target })
        let course_id = this.course ? this.course.id : null
        api.User.create(this.user, this.user_fields, this.userType, course_id)
        .then( res => {
          EventBus.emit('loader:local:end', {
            elem: target
          })
          // is returnig the enroll
          if (res.user) {
            this.addUser(res.user)
            this.addEnroll(res)
          }
          // is returning only the user 
          else {
            this.addUser(res)
          }
          // location.reload() //temporary
        }).catch(err => {
          EventBus.emit('loader:local:end', {
            elem: target
          })
          console.error(err.code)
        }) 
      }
    }
  }
}
</script>