<template>
  <div class="CoachCard Box Box--Clear mb-6">

        <loading 
        :active.sync="isLoading" 
        :can-cancel="false" 
        :is-full-page="fullPage"
        :opacity="0.8"
        ></loading>

    <div class="Box__BgTitle mb-6">
      <h2 class="CoachCard__Title text-lg text-white sm:hidden">{{ coach.full_name }}</h2>
      <button v-if="vimeo" class="flex" @click="startVideo()">
        <span class="Btn Btn--Text mr-2 CoachCard__VideoCta">Scopri di più</span>
        <svg height="18" width="18" class="fill-current Boxed">
          <use xlink:href="#icon-play"></use>
        </svg>
      </button>
      <svg v-else height="18" width="18" class="fill-current Boxed">
        <use xlink:href="#icon-user"></use>
      </svg>
    </div>
    <div class="CoachCard__Inner">
      <div class="CoachCard__Inner__Img">
       <!-- <%= image_pack_tag 'test/profile.jpeg', class: 'logo' %>-->
       <img :src="coach.profileImage.url" class="logo" v-if="coach.profileImage && coach.profileImage.url" />
      </div>
      <div class="CoachCard__Inner__Text">
        <h2 class="CoachCard__Title text-lg text-white hidden sm:block">{{ coach.full_name }}</h2>
        <p class="CoachCard__Job"><strong>{{ headline }}</strong></p>
        <p>{{ description }}</p>
        <button v-if="calendlyEnabled" class="Btn Btn--Save mt-4" @click="selectCalendly(coach)">Prenota</button>
      </div>
    </div>


    <div v-if="calendlyEnabled" class="CoachCard__Calendly" :id="`calendly_embeed_${coach.id}`" :style="`min-width:320px;height:${selectedCalendly === coach.id ? '100' : '0'}%;`"></div>
    <button v-if="selectedCalendly === coach.id" @click="selectedCalendly = ''" class="CoachCard__Calendly__Close">
      <svg height="20" width="20" class="fill-current">
        <use xlink:href="#icon-close"></use>
      </svg>
    </button>

    <modal name="video-coach" height="auto" class="modal-video">
      <div class="v--modal--header p-4">
        <h2 class="text-xl">{{ coach.full_name }}</h2>
         <button @click="$modal.hide('video-coach')" class="Btn Btn--Delete Btn--Icon">
          <svg height="12" width="12" class="fill-current"> 
            <use xlink:href="#icon-close"></use>
          </svg>
        </button>
      </div>
      <div class="v--modal--body">
         <div vclass="VideoContainer">
          <vue-vimeo-player
          :video-id="394452750"
          :options="{ responsive: true }"
          autoplay="true"></vue-vimeo-player>
        </div>
      </div>
    </modal>

  </div>
</template>

<script>
import api from '../../api'
import { vueVimeoPlayer } from 'vue-vimeo-player'

// Import component
import Loading from 'vue-loading-overlay';
// Import loading stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
  components: {
    vueVimeoPlayer
  },
  props: {
    currentUser: Object,
    coach: Object,
    tenant_name: String,
    calendlyEnabled: Boolean
  },
  data(){
    return {
      selectedCalendly: null,
      isLoading: false,
      fullPage: true
    }
  },
  components: {
      Loading
  },
  computed: {
    headline(){
      return this.coach.userFieldsCoach.filter( userField => userField.name === 'headline' )[0] ? this.coach.userFieldsCoach.filter( userField => userField.name === 'headline' )[0].value : ''
    },
    description(){
      return this.coach.userFieldsCoach.filter( userField => userField.name === 'description' )[0] ? this.coach.userFieldsCoach.filter( userField => userField.name === 'description' )[0].value : ''
    },
    vimeo(){
      return this.coach.userFieldsCoach.filter( userField => userField.name === 'vimeo' )[0] ? this.coach.userFieldsCoach.filter( userField => userField.name === 'vimeo' )[0].value : null
    },
    calendly(){
      return this.coach.userFieldsCoach.filter( userField => userField.name === 'calendly' )[0] ? this.coach.userFieldsCoach.filter( userField => userField.name === 'calendly' )[0].value : null
    }
  },
  methods: {
    selectCalendly(coach){
      this.selectedCalendly = coach.id

      let self = this
      Calendly.initInlineWidget({
        url: `${this.calendly}?hide_landing_page_details=1&hide_event_type_details=1&location=https://coaching.skillsincloud.com`,
        parentElement: document.getElementById(`calendly_embeed_${coach.id}`),
        prefill: {
          name: this.currentUser.full_name,
          email: this.currentUser.email
        },
        utm: {
          utmCampaign: `c${this.coach.id}_u${this.currentUser.id}`,
          utmSource: this.tenant_name
        },
      });
      function isCalendlyEvent(e) {
        return e.data.event &&
              e.data.event.indexOf('calendly') === 0;
      };
      window.addEventListener(
        'message',
        function(e) {
          console.log(e)
          if (isCalendlyEvent(e)) {
            if (e.data['event'] === "calendly.event_scheduled"){
              self.scheduleEvent()
            }
          }
        }
      );

    },
    scheduleEvent(){
      let self = this
      api.Coach.book( this.currentUser.id, this.coach.id )
      .then( res =>{
        self.selectedCalendly = ''
        self.isLoading = true;

        setTimeout(() => {
          window.location.href = '/'
        },3000)
        
      })
    },
    startVideo() {
      this.$modal.show('video-coach');
    }
  }
}
</script>