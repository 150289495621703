<template>
  <div class="EditSection mb-4">
    <div class="flex justify-between mb-4">
      <div class="flex items-center">
        <BackButton :href="`/admin/groups`" :cssClass="'mr-4'" />
        <h2 class="text-2xl font-bold">{{ group.name }}</h2>
      </div>
      
    </div>
    <form class="Form--Boxed mb-4">
      <div class="EditSection__Infos">
        <div class="grid grid-cols-2 gap-3">
          <div class="field">
            <label class="label">{{ t('pages.groups.name') }}</label>
            <input v-model="params.name" class="input" type="text"  :disabled="params.origin === 'hcm'"/>
          </div>
          <div class="field">
            <label for="type" class="label">{{ t('pages.groups.type') }}</label>
            <input v-if="params.origin === 'hcm'" type="text" class="input" :value="params.group_type" disabled="disabled"/>
            <select v-else name="type" id="type" v-model="params.group_type" class="Select capitalize">
              <option v-for="groupType in groupTypes" :key="`type-${groupType.id}`" :value="groupType.value">
                {{ t(groupType.label) }}
              </option>
            </select>
          </div>
           <div class="field">
            <label class="label" for="origin">{{ t('pages.groups.origin') }}</label>
            <select name="lang" id="origin" v-model="params.origin" class="Select capitalize" disabled="disabled">
              <option v-for="origin in origins" :key="`origin-${origin.id}`" :value="origin.value">
                {{ t(origin.label)}}
              </option>
            </select>
          </div>
          <div class="field">
            <label class="label">{{ t('pages.groups.description') }}</label>
            <input v-model="params.description" class="input" type="text" :disabled="params.origin === 'hcm'" />
          </div>
        </div>
    
        <div class="flex justify-end">
          <button @click.prevent="updateGroup(params)" class="Btn Btn--Save" type="submit">
            {{ t(`actions.save`) }}
          </button>
        </div>
      </div>
    </form>

    <div class="Form--Boxed mb-8"> 
      <div class="mb-4 flex items-center justify-between">
        <h4>{{ canEditUsers ? t('pages.groups.add_users') : t('pages.groups.users') }}</h4>

        <div v-if="canEditUsers">
          <button @click="makeManagerModal(user)" class="Btn Btn--Text text-warning mr-2">{{t('pages.groups.manage_managers')}}</button> 
          <button @click="openModal('addAll')" class="Btn Btn--Success">{{t('pages.groups.invite_all')}}</button> 
          <button @click="openModal('destroyAll')" class="Btn Btn--Delete">{{t('pages.groups.remove_all')}}</button>
        </div>
      </div>
     
      <div class="field flex">
        <input v-model="search" v-on:keyup.enter="getUsers(true)" type="text" :placeholder="t('pages.users.search_user')" class="mr-2"/>
        <button class="Btn Btn--Save" @click="getUsers(true)">{{t('actions.search')}}</button>
      </div>

      <template v-if="allUsers.length > 0">
        <ul class="List--Lined List--Lined--Thin">
          <li v-for="user in allUsers" :key="user.id" class="flex items-center justify-between">
            <span v-if="!user.inGroup" @click="addUser(user)" class="ml-2 cursor-pointer">{{ user.first_name }} {{ user.last_name }} - {{fiscalCodeLogin ? user.fiscal_code : user.email }}</span>
            <span v-else @click="removeUser(user)" class="ml-2 cursor-pointer">{{ user.first_name }} {{ user.last_name }} - {{ fiscalCodeLogin ? user.fiscal_code : user.email }}</span>
            <button class="flex items-center" v-if="!user.inGroup"  @click="addUser(user)" :disabled="!canEditUsers">
              <span class="mr-2 text-warning cursor-pointer">{{t('pages.groups.not_in_group')}}</span>
              <span class="Btn Btn--CheckStyle  Btn--CheckStyle--UnChecked "></span>
            </button>
            <div class="flex items-center gap-2" v-else >
              <span class="Tag Tag--Success"  v-if="user.groupUser.level === 1">
                Manager
                <button class="ml-2" @click="removeManager(user)">
                  <svg height="12" width="12" class="fill-current"> 
                    <use xlink:href="#icon-close"></use>
                  </svg>
                </button>
              </span>
              <button class="flex items-center" @click="removeUser(user)" :disabled="!canEditUsers">
                <span class="mr-2 text-success cursor-pointer">{{t('pages.groups.in_group')}}</span>
                <span class="Btn Btn--CheckStyle  Btn--CheckStyle--Checked">
                  <svg height="16" width="16" class="fill-current"> 
                    <use xlink:href="#icon-checkmark"></use>
                  </svg>
                </span>
              </button>
            </div>
          </li>
        </ul> 
        <div class="text-right mt-2">
          <button v-if="addedUsers.length > 0 || removedUsers.length > 0" class="Btn Btn--Save" @click="openModal('addUsers')">{{t('actions.save_changes')}} ({{addedUsers.length+removedUsers.length}} {{t('global.users')}})</button>
        </div>
        <div class="text-center mt-2" >
          <paginate
            v-model="page"
            :page-count="pagy.pages"
            :click-handler="pageCallback"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'PaginationList'">
          </paginate>
        </div>
      </template>
      <template v-else-if="loading">
        <li class="LoadingBox">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </li>
      </template>
       <div v-else class="pt-8 text-center pb-8">
        <p class="text-lg">{{t('pages.users.no_users')}}</p>
      </div>
    </div>

    <modal name="make-manager" height="auto">
      <div class="v--modal--header p-4">
        <h2 class="text-xl">{{t('pages.groups.add_manager')}}</h2>
      </div>
      <div class="v--modal--body body--users p-4">
        <p class="mb-4">{{t('pages.groups.set_manager_email')}}:</p>
        <div class="field flex">
          <input v-model="managerEmail" v-on:keyup.enter="addManager()" type="text" :placeholder="t('pages.groups.set_email')" class="mr-2"/>
          <button class="Btn Btn--Save" @click="addManager()">{{t('actions.save')}}</button>
        </div>
      </div>
      <div class="v--modal--actions sm:flex sm:justify-end p-4">
        <button class="Btn Btn--Delete ml-2" @click="$modal.hide('make-manager')">{{t('actions.close')}}</button>
      </div>
  </modal>

    <modal name="confirm-modal" height="auto">
      <div class="v--modal--header p-4">
        <h2 class="text-xl">{{t('pages.course.editing')}}:</h2>
      </div>
      <div class="v--modal--body body--users p-4">
        <template  v-if="modalType === 'addUsers'">
          <div v-if=" addedUsers.length > 0" class="mb-4">
            <h4 class="mb-2">{{t('pages.course.users_added')}}: ({{ addedUsers.length }})</h4>

            <ul class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
              <li v-for="user in addedUsers" :key="user.id">
              {{ user.email }}
              </li>
            </ul>
          </div>
          <div v-if=" removedUsers.length > 0" class="mb-4">
            <h4 class="mb-2">{{t('pages.course.users_removed')}}: ({{ removedUsers.length }})</h4>
            <ul v-if=" removedUsers.length > 0" class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
              <li v-for="user in removedUsers" :key="user.id">
                {{ user.email }}
              </li>
            </ul>
          </div>
        </template>
        <template  v-else-if="modalType  === 'enrollAll'">
          <p>{{t('pages.course.confirm_invite_all')}}</p>
        </template>
        <template  v-else-if="modalType  === 'destroyAll'">
          <p>{{t('pages.course.confirm_remove_all')}}</p>
        </template>
      </div>
      <div class="v--modal--actions sm:flex sm:justify-end p-4">
        <button class="Btn Btn--Save" @click="saveUsers">{{t('actions.save')}}</button>
        <button class="Btn Btn--Edit ml-2" @click="$modal.hide('confirm-modal')">{{t('actions.undo')}}</button>
      </div>
    </modal>

    </div>
    

  


</template>

<script>
import api from '../../../api'
import BackButton from '../../commons/backButton'
import ImgUpload from '../../commons/imgUpload'
import { mapState, mapActions, mapGetters } from 'vuex'
import { VueEditor } from "vue2-editor";
import { global as EventBus } from '../../../js/utils/eventBus';


export default {
  name: 'AdminGroupEdit',
  props: {
    group: Object,
  },
  components: {
    BackButton,
    ImgUpload,
    VueEditor
  },
  data() {
    return {
      loading: false,
      search: '',
      managerEmail: '',
      page: 1,
      pagy: {},
      light: true,
      error: '',
      pageOfItems: [],
      customLabels: {
        previous: '«',
        next: '»'
      },
      params: {
        name: this.group.name,
        origin: this.group.origin,
        group_type: this.group.group_type,
        description: this.group.description,
      },
      addedUsers: [],
      removedUsers: [],
      modalType: '',
      origins: [
        { id: 1, value: 'manual', label:'pages.groups.manual'},
        { id: 2, value: 'auto', label: 'pages.groups.auto'},
        { id: 3, value: 'hcm', label: 'pages.groups.hcm'},
      ],
      groupTypes: [
        { id: 1, value: 'team', label: 'user_field.team'},
        { id: 2, value: 'department', label: 'user_field.department'},
        { id: 3, value: 'area', label: 'user_field.area'},
        { id: 4, value: 'business_unit', label: 'user_field.business_unit'},
      ],
      addedManager: {},
    }
  },

  watch: {
  'params.notification_event_id': function(newValue) {
    if (newValue !== 3) {
      //this.params.notification_type = 'after';
      console.log(newValue)
    }
  }
},

  computed: {

    ...mapState({
      allUsers: state => state.user.allUsers
    }),

    canEditUsers() {
      return this.group.origin === 'manual'
    },
    


  },
  mounted() {
    this.getUsers()
  },
  methods: {

    ...mapActions({
      getAllUsers: 'getAllUsers',
      addEnrolls: 'addEnrolls'
    }),

    getUsers(fromSearch){
      this.loading = true
      api.User.getUsersFromSearch({
        user_type: 'all', 
        page:  fromSearch ? 1 : this.page,
        search: this.search,
        group_id:  this.group.id
      })
      .then( res => {
        this.getAllUsers(res.users)
        this.pagy = res.pagy
        this.loading = false
        this.addedUsers = [] 
        this.removedUsers = []
      })
      .catch(err => {
        this.loading = false
        console.log(err)
      })
    },

    addUser(user) {
      user.inGroup = true
      
      // user was enrolled but added to removes user. doesn't have to be added
      if ( this.removedUsers.filter(item => item.id === user.id ).length > 0  ) {
        this.removedUsers = this.removedUsers.filter(function( obj ) {
          return obj.id !== user.id;
        });
      } else {
        this.addedUsers.push(user)
      }
    },

    removeUser(user) {
      user.inGroup = false
      // user was removed but added to added user. doesn't have to be removed
      if ( this.addedUsers.filter(item => item.id === user.id ).length > 0  ) {
        this.addedUsers = this.addedUsers.filter(function( obj ) {
          return obj.id !== user.id;
        });
      } else {
        this.removedUsers.push(user)
      }
      
    },

    pageCallback(pageNum){
      console.log(pageNum)
      if (pageNum != undefined) {
        this.page = pageNum
      }
      this.getUsers()
    },


    updateGroup(params) {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      api.Group.update(this.group.id, params)
        .then(res => {
          this.group = res;
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target })
        })
        .catch(err => {
          EventBus.emit('loader:local:end', { elem: target })
          this.$toastr.e(this.t('actions.error'));
        })
    },

    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems;
    },

    removeManager(user) {
      if(confirm( this.t(`pages.groups.confirm_remove_manager`) )){
        api.Group.updateGroupUser(user.groupUser.id, { level: 0 })
          .then(res => {
            this.$toastr.s(this.t('actions.saved'));
            this.getUsers()
          })
          .catch(err => {
            this.$toastr.e(this.t('actions.error'));
          }
        )
      }
    },

    addManager() {
      const elem = event.currentTarget
      EventBus.emit('loader:local:start', { elem })
      api.Group.addManager(this.group.id, { email: this.managerEmail })
        .then(res => {
          console.log(res)
          EventBus.emit('loader:local:end', {elem})
          this.$toastr.s(this.t('pages.groups.user_added_as_manager'));
          setTimeout(() => {
            location.reload()
          }, 500)
        })
        .catch(err => {
          EventBus.emit('loader:local:end', {elem})
          if (err.response && err.response.data && err.response.data.errors) {
            this.$toastr.e(this.t(`pages.groups.${err.response.data.errors}`));
          } else {
            this.$toastr.e(this.t('actions.error'));
          }
        }
      )
    },

    openModal(modalType, name='confirm-modal') {
      this.modalType = modalType
      this.$modal.show(name);
    },

    saveMakeManager() {
      console.log(this.addedManager)
      api.Group.updateGroupUser(this.addedManager.groupUser.id, { level: 1 })
        .then(res => {
          this.$toastr.s(this.t('actions.saved'));
          this.$modal.hide('make-manager')
        })
        .catch(err => {
          this.$toastr.e(this.t('actions.error'));
        }
      )
    },


    makeManagerModal(user) {
      this.addedManager = user
      this.openModal('makeManager', 'make-manager')
    },
    async saveUsers(){
    
      const elem = event.currentTarget
      this.addAll = this.modalType === 'addAll'
      this.destroyAll = this.modalType === 'destroyAll'
      EventBus.emit('loader:local:start', { elem })
      const addUsers = api.Group.addUsers(this.group.id, {
        user_ids: this.addedUsers.map(user => user.id),
        addAll: this.addAll
      });
      const removeUsers = api.Group.removeUsers(this.group.id, {
        user_ids: this.removedUsers.map(user => user.id),
        destroyAll: this.destroyAll
      });
      Promise.all([await addUsers, await removeUsers])
        .then( res => {
          this.addedUsers = [];
          this.removedUsers = [];
          this.$modal.hide('confirm-modal');
          EventBus.emit('loader:local:end', {elem})
          this.$toastr.s(this.t('actions.saved'));
          if (this.addAll || this.destroyAll) location.reload()
        }
      ).catch(err => {
        this.$toastr.e(this.t('errors.messages.try_again'));
        EventBus.emit('loader:local:end', {elem})
      })
    },
  }


}

</script>
<style lang="scss">

#cn-lang {
  text-transform: uppercase;
  max-width: 200px;
}

.ToggleContainer {
  height: 40px;
}

</style>