<template>
  <div>
    <div class="StickyBar flex flex-wrap gap-2 justify-between mb-4">
      <h2 class="text-2xl">
        <a class="Btn Btn--Primary Btn--Icon mb-2 mr-4" href="/manager/groups">
          <svg height="16" width="16" class="icon-arrow-left fill-current">
            <use xlink:href="#icon-arrow-left"></use>
          </svg>
        </a>
        {{t('global.stats')}} {{t('pages.groups.title.s')}}: {{ groupName }}</h2>
      <div>
        <date-picker confirm="true" v-model="dateRange" :placeholder="t('global.choose_data_range')" range></date-picker>
      </div>
    </div>
    <div class="grid sm:grid-cols-2 gap-3 mb-4"></div>
      <div class="Hero Box">
        <img src="../../images/party2.png" class="Hero__Img2" alt="Party Image">
        <div class="Hero__Caption">
          <span class="Hero__Icon">
            <svg height="16" width="16" class="icon-mood-happy-outline fill-current ciao">
              <use xlink:href="#icon-mood-happy-outline"></use>
            </svg>
          </span>
          <h1 class="text-2xl mb-3 font-bold capitalize">{{t('pages.home.welcome')}} {{user.first_name}}</h1>
          <p class="text-xl mb-3">{{t('pages.stats.find_out_groups_progress')}}</p>
          <hr class="Hero__Separator mb-3">
          <div>
            <span class="pr-2 inline-block"><strong>{{ t('global.user.p') }}:</strong> {{ info.users_num }}</span>
            <span><strong>{{t('global.course.p')}} {{ t('pages.stats.started_pl') }}:</strong> {{ info.started_num }}</span>
          </div>
        </div>
      </div>
      <div class="grid sm:grid-cols-3 gap-3 mb-4 mt-4">
        <div class="Box Box--Clear text-center">
          <p class="font-bold text-xl mb-4">{{t('pages.stats.how_long_teams_formed')}}</p>
          <div v-if="loading.graphs" class="LoadingBox LoadingBox--Small text-center">
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
          <div class="font-bold" v-else>
            <p class="text-4xl">{{ ssToHH(globalActivities.total_duration) }}</p>
          </div>
        </div>
        <div class="Box Box--Clear col-span-2">
          <BgTitle :title="t('pages.stats.team_favourite_courses')" icon="calendar"></BgTitle>
          <span class="Tag" v-for="course in favouriteCourses" :key="course.id">{{ course }}</span>
        </div>
      </div>
      <div class="grid sm:grid-cols-2 gap-3 mb-4">
        <div class="Box Box--Clear ">
          <p class="font-bold text-xl mb-4">{{ t('pages.stats.team_activity', { rangeActive: rangeActive ? t('global.selected_period') : t('global.last_12_months') }) }}?</p>

          <div v-if="loading.graphs" class="LoadingBox LoadingBox--Small text-center">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
          <div v-else>
            <column-chart :data="convertSeconds(globalActivities.months_by_duration)" :colors="[`${colorPrimary}`]"></column-chart>
            <p class="mt-3 text-sm text-center">{{ t('pages.stats.minutes_studied_month')}}</p>
          </div>
        </div>
        
        <div class="Box Box--Clear">
          <p class="font-bold text-xl mb-4">{{t('pages.stats.areas_team_developing')}}</p>
          <pie-chart v-if="areas > 0"  :data="enrollsByArea" legend="bottom" ></pie-chart>
          <div v-else class="pt-4 text-center">
            <p>{{t('pages.stats.no_area_in_platform')}}</p>
            <p>{{t('pages.stats.add_and_associate_with_course')}}</p>
          </div>
        </div>

        <div class="Box Box--Clear ">
          <p class="font-bold text-xl mb-4">{{t('pages.stats.days_team_prefer_study')}}</p>
          <div v-if="loading.graphs" class="LoadingBox LoadingBox--Small text-center">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
          <div v-else>
            <column-chart :data="convertSeconds(globalActivities.days_by_duration)" :colors="[`${colorPrimary}`]" ></column-chart>
            <p class="mt-3 text-sm text-center">{{t('pages.stats.minutes_study_day_week')}}</p>
          </div>
        </div>
        
        <div class="Box Box--Clear ">
          <p class="font-bold text-xl mb-4">{{t('pages.stats.time_team_prefer_study')}}</p>
          <div v-if="loading.graphs" class="LoadingBox LoadingBox--Small text-center">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
          <div v-else>
            <column-chart :data="convertSeconds(globalActivities.hours_by_duration)" :colors="[`${colorPrimary}`]"></column-chart>
            <p class="mt-3 text-sm text-center">{{t('pages.stats.minutes_study_time_slot')}}</p>
          </div>
        </div>
      </div>
      
      <div class="mb-8 mt-5">
        <div class="flex flex-wrap gap-2 justify-between mb-4">
          <h2 class="text-2xl">{{t('global.stats')}} {{ t('global.users') }}</h2>
          <div class="flex">
            <input v-model="searchUsers" type="text" :placeholder="t('global.search_users')" class="flex-1 mr-2" @keyup.enter="getUsersFromSearch" />
            <button @click="getUsersFromSearch" class="Btn Btn--Save">{{t('actions.search')}}</button>
          </div>
        </div>

        <div class="Table__Container">
          <table class="Table table-auto">
            <thead>
              <tr>
                <th class="text-left">{{t('global.users')}}</th>
                <th class="text-left">
                  <button :class="{active: userSortBy === 'duration'}" @click="sortUsers('duration')" class="inline-flex items-center text-left">
                    <strong>{{t('global.total_hours')}}</strong>
                    <span class="ml-1">{{ userSortOrder === 'desc' ? '↓' : '↑' }}</span>
                  </button>
                </th>
                <th class="text-left">{{t('global.courses_completed')}}</th>
                <th class="text-left">
                  <button :class="{active: userSortBy === 'last_activity'}" @click="sortUsers('last_activity')" class="inline-flex items-center text-left">
                    <strong>{{t('global.last_activity')}}</strong>
                    <span class="ml-1">{{ userSortOrder === 'desc' ? '↓' : '↑' }}</span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody v-if="!loading.list">
              <tr v-for="(user, i) in pageOfUsers" :key="`user-${i}`">
                <td class="Table__UserName text-left"><a :href="`/admin/users/${user.id}`">{{user.first_name}} {{user.last_name}}</a></td>
                <td class="text-left">{{ssToHH(user.duration)}}</td>
                <td class="text-left">{{user.completed_courses}}</td>
                <td class="text-left">{{ user.last_activity }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="text-center mt-4">
          <jw-pagination :items="users" @changePage="onChangePageUsers" :labels="customLabels" class="PaginationList"></jw-pagination>
        </div>
      </div>

      <div class="mb-8">
        <div class="flex flex-wrap gap-2 justify-between mb-4">
          <h2 class="text-2xl">{{t('pages.stats.courses')}}</h2>
          <div class="flex">
            <input v-model="search" type="text" :placeholder="t('pages.courses.search_courses')" class="flex-1 mr-2" @keyup.enter="getCoursesFromSearch" />
            <button @click="getCoursesFromSearch" class="Btn Btn--Save">{{t('actions.search')}}</button>
          </div>
        </div>

        <div class="Table__Container">
          <table class="Table table-auto">
            <thead>
              <tr>
                <th class="text-left">{{t('global.courses.p')}}</th>
                <th class="text-left">
                  <button :class="{active: activeSort === 'enrolls'}" @click="sort('enrolls')" class="inline-flex items-center text-left">
                    <strong>{{t('pages.stats.enrolled_users')}}</strong>
                    <span class="ml-1">{{ toSortBy === 'asc' ? '↓' : '↑' }}</span>
                  </button>
                </th>
                <th class="text-left">
                  <button :class="{active: activeSort === 'started'}" @click="sort('started')" class="inline-flex items-center text-left">
                    <strong>{{t('pages.stats.started_users')}}</strong>
                    <span class="ml-1">{{ toSortBy === 'asc' ? '↓' : '↑' }}</span>
                  </button>
                </th>
                <th class="text-left">
                  <button :class="{active: activeSort === 'completed'}" @click="sort('completed')" class="inline-flex items-center text-left">
                    <strong>{{t('pages.stats.completed_users')}}</strong>
                    <span class="ml-1">{{ toSortBy === 'asc' ? '↓' : '↑' }}</span>
                  </button>
                </th>
                <th class="text-left">{{ t('actions.export_stats') }}</th>
              </tr>
            </thead>
            <tbody v-if="!loading.list">
              <tr v-for="(course, i) in pageOfCourses" :key="`course-${i}`">
                <td class="text-left"><a :href="course.url">{{course.name}}</a></td>
                <td class="text-left">{{course.enrolls}}</td>
                <td class="text-left">{{course.started}}</td>
                <td class="text-left">{{course.completed}}</td>
                <td class="text-left">
                  <button @click.prevent="exportStats(course)" class="Btn Btn--Save" type="button">
                    <svg height="16" width="16" class="fill-current">
                      <use xlink:href="#icon-download"></use>
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="text-center mt-4">
          <jw-pagination :items="enrollsByCourses" @changePage="onChangePageCourses" :labels="customLabels" class="PaginationList"></jw-pagination>
        </div>
      </div>
      <modal name="export-confirm-modal" height="auto" width="400px">
        <div class="v--modal--header p-4">
          <h2 class="text-xl font-bold">{{ t('actions.export_successful') }}</h2>
        </div>
        <div class="v--modal--body p-4">
          <p>{{ t('actions.export_confirm_message') }}</p>
        </div>
        <div class="v--modal--actions sm:flex sm:justify-end p-4">
          <button class="Btn Btn--Edit ml-2" @click="$modal.hide('export-confirm-modal')">{{ t('actions.close') }}</button>
        </div>
      </modal>
    </div>
</template>

<script>
import api from '../../api';
import DatePicker from 'vue2-datepicker';
import BgTitle from '../commons/bgTitle.vue';
import { global as EventBus } from '../../js/utils/eventBus';

export default {
  props: {
    user: Object,
    info: Object,
    colorPrimary: String,
    areas: Number,
    totDuration: Number
  },
  data() {
    return {
      enrollsByCourses: [],
      enrollsByArea: [],
      users: [],
      page: 1,
      pageOfUsers: [],
      pagy: {},
      pagyUsers: {},
      loading: {
        list: true,
        graphs: true,
        users: true
      },
      errGraphs: '',
      error: false,
      pageOfCourses: [],
      toSortBy: 'asc',
      activeSort: 'enrolls',
      userSortOrder: 'desc',
      userSortBy: 'duration',
      search: '',
      searchUsers: '',
      dateRange: [],
      general: {},
      globalActivities: {},
      customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      },
      groupName: this.info.group_name || 'Nome non disponibile',
      groupId: this.info.group_id,
      loadingExport: false,
      loadingExportId: null,
    };
  },
  components: {
    DatePicker,
    BgTitle
  },
  mounted() {
    this.getEnrollsByCourses();
    this.getEnrollsbyArea();
    this.getGlobalActivities();
    this.getUserStats();
    console.log('Nome del gruppo:', this.groupName);
    console.log('Dati ricevuti da info.users:', this.info.users);
    console.log('Dati ricevuti da info.started:', this.info.started);
    console.log("Info object:", this.info);
  },
  computed: {
    filteredCourses() {
      return this.enrollsByCourses.map(el => [el.name, el.enrolls]);
    },
    favouriteCourses() {
      return this.uniqueStartedCourses.map(course => course.course_name).slice(0, 6);
    },
    chartData() {
      return this.filteredCourses.slice(0, 10);
    },
    rangeActive() {
      return this.dateRange.length > 0 && this.dateRange[0] !== null && this.dateRange[1] !== null;
    },
    uniqueStartedCourses() {
      const uniqueCourses = {};
      return this.info.started.filter(course => {
        if (!uniqueCourses[course.course_name]) {
          uniqueCourses[course.course_name] = true;
          return true;
        }
        return false;
      });
    },
  },
  watch: {
    dateRange() {
      this.getEnrollsByCourses();
      this.getGeneralInfo();
      this.getGlobalActivities();
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');
      return `${day}/${month}/${year} - ${hours}:${minutes}`;
    },
    exportStats(course) {
      if (!course.id) {
        console.error("Course ID is undefined for course:", course);
        this.$toastr.e(this.t('errors.course_not_found'));
        return;
      }
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target });
      const courseParams = { id: course.id, users_ids: course.users_ids };
      this.loadingExportId = course.id;
      api.Course.exportCourseStatsManager(courseParams)
        .then(() => {
          setTimeout(() => {
            this.loadingExportId = null;
            EventBus.emit('loader:local:end', { elem: target });
            this.$modal.show('export-confirm-modal');
          }, 1000);
        })
        .catch(err => {
          console.error("Export error:", err);
          this.$toastr.e(this.t('actions.error'));
          EventBus.emit('loader:local:end', { elem: target });
        });
    },
    getEnrollsbyArea() {
      console.log('getEnrollsbyArea')
      api.Stats.getEnrollsByAreaManager({
        group_id: this.info.group_id,
      })
        .then( res => {
          this.enrollsByArea = res
        }).catch(err => {
          this.err = err
        })
    },
    ssToHH(totSecs) {
      let totalSeconds = totSecs || 0;
      const hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      const minutes = Math.floor(totalSeconds / 60);
      return `${hours}h:${minutes}m `;
    },
    convertSeconds(result) {
      if (!result) return {};
      return Object.keys(result).reduce((acc, key) => {
        acc[key] = parseFloat((result[key] / 60).toFixed(0));
        return acc;
      }, {});
    },
    getCoursesFromSearch() {
      this.page = 1;
      this.getEnrollsByCourses();
    },
    getUsersFromSearch() {
      this.pageUsers = 1;
      this.getUserStats();
    },
    getEnrollsByCourses() {
      api.Stats.getEnrollByCoursesManager({
        page: this.page,
        search: this.search,
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
        group_id: this.info.group_id,
      })
        .then(res => {
          console.log("Risposta da getEnrollByCoursesManager:", res);
          this.enrollsByCourses = res.enrolls_by_courses;
          this.pageOfCourses = this.enrollsByCourses.slice(0, 10);
          this.loading.list = false;
        })
        .catch(err => {
          console.error("Errore in getEnrollByCourses:", err);
          this.error = err;
          this.loading.list = false;
        });
    },
    getUserStats() {
      api.Stats.getUserStatsManager({
        page: this.pageUsers,
        search: this.searchUsers,
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
        order: this.userSortOrder,
        sort: this.userSortBy,
        group_id: this.info.group_id,
      })
        .then(res => {
          this.users = res.users;
          this.pageOfUsers = this.users.slice(0, 10);
          this.pagyUsers = res.pagy;
          this.loading.users = false;
          console.log('Dati utenti:', this.users);
        })
        .catch(err => {
          this.error = err;
          this.loading.users = false;
        });
    },
    getGeneralInfo() {
      api.Stats.getGeneralInfo({
        start_date: this.dateRange[0],
        end_date: this.dateRange[1]
      })
        .then(res => {
          this.general = res;
        })
        .catch(err => {
          this.error = err;
        });
    },
    getGlobalActivities() {
      api.Stats.getGlobalActivitiesManager({
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
        group_id: this.info.group_id,
      })
        .then(res => {
          this.globalActivities = res;
          this.loading.graphs = false;
          console.log('Dati globalActivities:', this.globalActivities);
        })
        .catch(err => {
          this.errGraphs = err;
          this.loading.graphs = false;
        });
    },
    onChangePageCourses(pageOfCourses) {
      this.pageOfCourses = pageOfCourses;
    },
    onChangePageUsers(pageOfUsers) {
      this.pageOfUsers = pageOfUsers;
    },
    pageCallback(pageNum) {
      if (pageNum !== undefined) {
        this.page = pageNum;
      }
      if (this.search) {
        this.getUsersFromSearch();
      } else {
        this.getUserStats();
      }
    },
    sortUsers(value) {
      this.userSortOrder = this.userSortOrder === 'asc' ? 'desc' : 'asc';
      this.userSortBy = value;
      this.getUserStats();
    },
    sort(value) {
      if (this.activeSort !== value) this.toSortBy = 'desc';
      this.activeSort = value;
      this.enrollsByCourses.sort((a, b) => 
        this.toSortBy === 'asc' ? a[value] - b[value] : b[value] - a[value]
      );
      this.toSortBy = this.toSortBy === 'asc' ? 'desc' : 'asc';
    }
  }
};
</script>