<template>
  <div>
    <div class="Box Box--Clear mb-4">
      <h4 class="text-xl mb-4 flex items-center justify-between">Recap</h4>
      <div class="sm:grid grid-cols-2 gap-3">
        <div>
          <p><strong>Corsi:</strong></p>
          <p class="">{{academy.courses}}</p>
        </div>
        <div>
          <p><strong>Utenti:</strong></p>
          <p class="">{{academy.users}}</p>
        </div>
         <div>
          <p><strong>Ultima attività:</strong></p>
          <p class="">{{academy.last_activity || 'Nessuna attività'}}</p>
        </div>
        <div>
          <p><strong>Scadenza contratto:</strong></p>
          <p class="">{{academy.expiry_date || 'Non presente'}}</p>
        </div>
        <div>
          <p><strong>Abbonamento al catalogo:</strong></p>
          <p class="">{{academy.catalog_subscription || 'No'}}</p>
        </div>
        <div>
          <p><strong>Abbonamento ai live:</strong></p>
          <p class="">{{academy.live_subscription || 'No'}}</p>
        </div>
        <div>
          <p><strong>Abbonamento ai corsi gratuiti:</strong></p>
          <p class="">{{academy.free_courses_subscription || 'No'}}</p>
        </div>
        <div>
          <p><strong>Schermata accettazione TOS abilitata:</strong></p>
          <p class="">{{academy.tos_needed || 'No'}}</p>
        </div>
      </div>
      <div class="text-right mt-4">
        <a :href="`/admin/${academy.name}/edit`" class="Btn Btn--Edit">{{t('actions.edit')}}</a>
      </div>
    </div>

    <CourseList :tenant="tenant" type="courses" />
    <CourseList :tenant="tenant" type="paths" />


  </div>
  
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '../../../api'
import CourseList from './courseList'


export default {

  props: {
    tenant: String
  },

  components: {
    CourseList
  },

  data() {
    return {
      academy: {},
      tenantCourses: [],
      lacerbaCourses: [],
      pageOfItems: [],
      customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      },
      loading: false,
      search: '',
      error: '',
      page: 1,
      addedCourses: [],
      removedCourses: []
    }
  },
  
  computed: {
    ...mapState({ 
      //allAcademies: state => state.general.allAcademies
    }),

    filteredCourses() {
      let filteredCourses = []
      filteredCourses =  this.lacerbaCourses.map((el) => {
        return  {...el, included: this.tenantCourses.some(course => course.external_course_id === el.id)} 
      })
      return filteredCourses
    }


  },
  mounted() {
   this.showAcademy()
   this.getTenantCourses(this.tenant)
   this.getLacerbaCourses('lacerba-api-fad')
  },
  
  methods: {

     ...mapActions({
      //getAllAcademies: 'getAllAcademies'
    }),

    getTenantCourses() {
      api.General.getCourses({ tenant: this.tenant }).then(res => {
        this.tenantCourses = res.courses
      }).catch( err => {
        this.error = err.message
      })
    },

    getLacerbaCourses() {
      api.General.getCourses({ 
        tenant:'lacerba-api-fad',
        search: this.search
      }).then(res => {
        this.lacerbaCourses = res.courses
      }).catch( err => {
        this.error = err.message
      })
    },
      
    showAcademy() {
      api.General.showAcademy({
        tenant: this.tenant,
        search: this.search
      }).then(res => {
        this.academy = res
      }).catch( err => {
        this.error = err.message
        this.loading = false
      })
    },


    getCoursesFromSearch() {
      this.getTenantCourses(this.tenant)
      this.getLacerbaCourses('lacerba-api-fad')
    },

    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },


    addCourse(course) {
      course.included = true
      
      // user was enrolled but added to removes user. doesn't have to be added
      if ( this.removedCourses.filter(item => item.id === course.id ).length > 0  ) {
        this.removedCourses = this.removedCourses.filter(function( obj ) {
          return obj.id !== course.id;
        });
      } else {
        this.addedCourses.push(course)
      }
    },
    removeCourse(course) {
      course.included = false
      // user was not enrolled but added to added user. doesn't have to be removed
      if ( this.addedCourses.filter(item => item.id === course.id ).length > 0  ) {
        this.addedCourses = this.addedCourses.filter(function( obj ) {
          return obj.id !== course.id;
        });
      } else {
        this.removedCourses.push(course)
      }
      
    },
    addAllCourses() {
      this.allCourses.forEach(el => {
        this.addCourse(el)
        this.allSelected = true
      })
    },
    undoAddAllCourses() {
      location.reload()
    },
    openModal() {
      this.$modal.show('confirm-modal');
    },

    async saveCourses() {
      const addCourses = api.General.addCourses({
        ids: this.addedCourses.map(el => el.id),
        tenant: this.tenant
      })
      const removeCourses = console.log('a')
      const destroyEnrolls = api.Enroll.destroyEnrolls([this.user], this.removedCourseIds);
      Promise.all([await addCourses, await removeCourses])
        .then( res => {
          this.addedCourses = [];
          this.removedUsers = [];
          this.$modal.hide('confirm-modal');
        })
        .catch(err => {
          console.log(err)
        })
    }

  }
}
</script>