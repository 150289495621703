import Home from "../components/admin/academy_setting/home.vue";
import Pages from "../components/admin/academy_setting/pages.vue";
import Branding from "../components/admin/academy_setting/branding.vue";
import Zoom from "../components/admin/academy_setting/zoom.vue";
import Settings from "../components/admin/academy_setting/settings.vue";
import Login from "../components/admin/academy_setting/login.vue";
import Webhook from "../components/admin/academy_setting/webhook.vue";
import Notifications from "../components/admin/academy_setting/notifications.vue";
import Gamification from "../components/admin/academy_setting/gamification.vue";
import Groups from "../components/admin/academy_setting/groups.vue";
import HomeLayout from "../components/admin/academy_setting/homeLayout.vue";

const routes = [
  {
    path: "/branding",
    name: "Branding",
    component: Branding,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/pages",
    name: "Pages",
    component: Pages,
  },
  {
    path: "/zoom",
    name: "",
    component: Zoom,
  },
  {
    path: "/settings",
    name: "",
    component: Settings,
  },
  {
    path: "/login",
    name: "",
    component: Login,
  },
  {
    path: "/webhook",
    name: "",
    component: Webhook,
  },
  {
    path: "/notifications",
    name: "",
    component: Notifications,
  },
  {
    path: "/gamification",
    name: "",
    component: Gamification,
  },
  {
    path: "/groups",
    name: "",
    component: Groups,
  },
  {
    path: "/home-layout",
    name: "",
    component: HomeLayout,
  },
  { path: "/", redirect: "/branding" },
];

export default routes;
