<template>
  <div class="Comments__Messages p-2" >
    <div  class="Comments__Messages__Item" >
      <div class="Comments__Messages__Info">
        <a  class="mr-4 font-bold" :href="`lectures/${comment.lecture.id}`" v-if="comment.lecture && !inLecture"><span class="text-primary">{{ t('pages.comments.lecture')}}:</span> {{ comment.lecture.name }}</a>
        <a @click="openChildren()" class="Btn Btn--Text text-primary">
          <strong>{{ comment.comments.length }} {{ comment.comments.length === 1 ? t('pages.comments.answer') : t('pages.comments.answers')  }} </strong>
        </a>
      </div>
      <p class="Comments__Messages__Title font-bold">{{ `${comment.user.first_name}` }}  </p>

      <editComment v-if="editComment === comment.id" :comment="comment" />

      <p v-else class="Comments__Messages__Text mt-2">{{ comment.body }}</p>

      <div v-if="comment.attachments.length > 0 && !editComment" class="file-preview mt-2 mb-2">
        <div v-for="(file, index) in comment.attachments" :key="index" class="file-item">
          <a target="_blank" :href="file.url" class="Btn Btn--Text text-primary">{{ file.filename }}</a>
        </div>
      </div>

      <a @click="openChildren()" class="Btn Btn--Text text-primary">{{ t('pages.comments.reply') }}</a>
      <a  v-if="currentUser.id === comment.user.id" @click="openEditComment(comment)" class="Btn Btn--Text text-warning">{{ t('pages.comments.edit') }}</a>
      <a  v-if="currentUser.id === comment.user.id || currentUser.admin_role === 'superAdmin'" @click="destroyComment(comment)" class="Btn Btn--Text text-danger">{{t('pages.comments.destroy')}}</a>
      <p class="text-grayScale-middle text-sm">{{ t('pages.comments.published') }} {{ comment.created_at  }}</p>
    </div>

    <template v-if="showChildren" >
      <div  class="Comments__Messages__Item Comments__Messages__Item--Reply" v-for="child in comment.comments" :key="child" >
        <p class="Comments__Messages__Title font-bold">{{  `${child.user.first_name}` }}</p>
        <editComment v-if="editComment === child.id" :comment="child" />
        <p  v-else class="Comments__Messages__Text mt-2">{{ child.body }}</p>
        <div v-if="child.attachments.length > 0" class="file-preview mt-2 mb-2">
          <div v-for="(file, index) in child.attachments" :key="index" class="file-item">
            <a target="_blank" :href="file.url" class="Btn Btn--Text text-primary">{{ file.filename }}</a>
          </div>
        </div>
        <a v-if="currentUser.id === child.user.id" @click="openEditComment(child)" class="Btn Btn--Text text-warning">{{ t('pages.comments.edit') }}</a>
        <a v-if="currentUser.id === child.user.id" @click="destroyComment(child)" class="Btn Btn--Text text-danger">{{t('pages.comments.destroy')}}</a>
        <p class="text-grayScale-middle text-sm">{{ t('pages.comments.published') }} {{ child.created_at  }}</p>
      </div>
    </template>

    <newComment v-if="showChildren" :course="course" :lecture="comment.lecture" :parent="comment"  :reply="true" />

  </div>
  
</template>

<script>
import newComment from '../commons/newComment'
import editComment from '../commons/editComment'
import api from '../../api'

export default {

  props: {
    currentUser: Object,
    comment: Object,
    inLecture: Boolean,
    course: Object
  },
  data() {
    return {
      showChildren: false,
      editComment: false,
    }
  },
  components: {
    newComment,
    editComment
  },

  methods: {
    openChildren() {
      this.showChildren = !this.showChildren
    },
    getComments(){
      this.$parent.getComments();
    },
    getChildComments(){
       api.Comment.show(this.comment)
        .then( res =>{
          this.comment = res
        })
    },
    openEditComment(comment){
      this.editComment = comment.id
    },
    closeEditComment() {
      this.editComment = false
    },
    destroyComment(comment){
      if(confirm( `Sei sicuro?` )){
        api.Comment.destroy(comment)
        .then(res => {
          this.$parent.getComments();
        })
      }
    }
  }
}
</script>