<template>
  <div class="EditSection mb-4">
    <div class="flex justify-between mb-4">
      <div class="flex items-center">
        <BackButton :href="`/admin/custom_notifications`" :cssClass="'mr-4'" />
        <h2 class="text-2xl font-bold">{{ customNotification.name }}</h2>
      </div>
      <div class="ToggleContainer field flex items-center field--checkbox gap-2">
        <Transition name="fade">
         <button @click.prevent="updateEnabled(enabled)"  v-if="isEnabledChanged" :class="['Btn', {' Btn--Save': enabled, 'Btn--Delete': !enabled}]" type="submit">
            {{ enabled ? t(`actions.save_to_activate`) : t(`actions.save_to_deactivate`) }}
          </button>
        </Transition>
        <label for="cn-enabled" :class="['text-lg font-bold', {'text-danger': !enabled, 'text-success': enabled}]">{{ t(`global.notification.s`)}}  {{ !enabled ? t('global.not') : '' }} {{t('global.active.sf') }}</label>
        <label for="cn-enabled" class="Switch">
          <input v-model="enabled" id="cn-enabled" type="checkbox">
          <span class="Slider round"></span>
        </label>
      </div>
    </div>
    <form class="Form--Boxed mb-4">
      <div class="EditSection__Infos">
        <div class="grid grid-cols-4 gap-3">
          <div class="field">
            <label for="cn-notification-event" class="text-lg">{{t('pages.custom_notifications.event_type')}}</label>
            <select v-model="params.notification_event_id" id="cn-notification-event" class="Select mb-4"
              aria-label="Tipo di evento">
              <option value="">{{ t('actions.select') }}...</option>
              <option v-for="event in notificationEvents" :value="event.id">{{ t(`notification_events.${event.event_type}`) }}</option>
            </select>
          </div>
          <div class="field" v-if="!isCustom">
            <label for="cn-notification-type" class="text-lg">{{t('pages.custom_notifications.moment')}}</label>
            <select v-model="params.notification_type" id="cn-notification-type" class="Select mb-4"
              aria-label="Tipo di notifica">
              <option value="">{{t('helpers.select.prompt')}}</option>
              <option value="after">{{t('pages.custom_notifications.after') }}</option>
              <option value="before">{{t('pages.custom_notifications.before') }}</option>
            </select>
          </div>
          <div class="field" v-if="!isCustom">
            <label for="cn-delay" class="inline-block text-lg">{{t('pages.custom_notifications.delta')}}</label>
            <input v-model="params.delay" id="cn-delay" class="mb-4" type="text" :placeholder="t('pages.custom_notifications.delta')"
              aria-label="Ritardo" />
          </div>
          <div class="field" v-if="!isCustom">
            <label for="cn-notification-freq" class="text-lg">{{t('pages.custom_notifications.frequency')}}</label>
            <input v-model="params.frequency" id="cn-notification-freq" class="mb-4" type="text" :placeholder="t('pages.custom_notifications.frequency')"
              aria-label="Frequenza" />
          </div>
        </div>
        <div class="flex justify-end">
          <button @click.prevent="updateCustomNotification(params)" class="Btn Btn--Save" type="submit">
            {{ t(`actions.save`) }}
          </button>
        </div>
      </div>
    </form>

    <div class="field">
      <label for="cn-langt" class="text-lg font-bold mb-2">{{t('global.language')}}</label>
      <select v-model="selectedLang" id="cn-lang" class="Select mb-4"  aria-label="Lang">
        <option class="" v-for="lang in languages" :value="lang.code">{{ lang.code }}</option>
      </select>
    </div>

    <form class="Form--Boxed mb-4">
      <!-- alla creaz di custon_notificatio, vanno create le translations con tutte le lingue della piattaforma -->
      <div class="EditSection__Infos" v-for="translation in translations">
        <div  v-if="selectedLang === translation.language">
          <div class="field">
            <label for="cn-subject" class="text-lg">{{t('pages.custom_notifications.subject')}}</label>
            <input v-model="translation.subject" id="cn-subject" class="mb-4" type="text" placeholder="Oggetto..."
              aria-label="Oggetto" />
          </div>
          <div class="grid grid-cols-3 gap-4">
            <div class="field col-span-2">
              <label for="cn-body" class="text-lg">{{t('pages.custom_notifications.body')}}</label>
              <vue-editor 
                v-model="translation.body" 
                id="cn-body" 
                class="mb-4" 
                placeholder="Scrivi qui il corpo della mail..." 
                aria-label="Corpo" 
                :editorToolbar="customToolbar"/>
            </div>
            <div class="col-span-1">
              <p class="font-bold text-lg">{{t('pages.custom_notifications.dynamic_fields.title')}}:</p>
              <p class="text-sm mb-2">{{t('pages.custom_notifications.dynamic_fields.description')}}</p>
              <ul>
                <li>#[user_name]: {{t('pages.custom_notifications.dynamic_fields.user_name')}}</li>
                <li>#[courses_list]: {{t('pages.custom_notifications.dynamic_fields.courses_list')}}</li>
                <li>#[course_name]: {{t('pages.custom_notifications.dynamic_fields.course_name')}}</li>
                <li>#[home_page]: {{t('pages.custom_notifications.dynamic_fields.home_link')}}</li>
                <li v-if="isLiveStartingSoon">#[live_link]: {{t('pages.custom_notifications.dynamic_fields.live_link')}}</li>
                <li v-if="isLiveStartingSoon">#[live_name]: {{t('pages.custom_notifications.dynamic_fields.live_name')}}</li>
                <li v-if="isLiveStartingSoon">#[live_date]: {{t('pages.custom_notifications.dynamic_fields.live_date')}}</li>
                <li v-if="isLiveStartingSoon">#[live_time]: {{t('pages.custom_notifications.dynamic_fields.live_time')}}</li>
              </ul>
            </div>
          </div>
          <div class="flex justify-end">
            <button @click.prevent="updateTranslation(translation)" class="Btn Btn--Save" type="submit">
              {{ t(`actions.save`) }}
            </button>
          </div>
        </div>
        
      </div>
    </form>

    <div class="Form--Boxed mb-8" v-if="!isCustom && !isNeverLoggedIn">
      <h4 class="mb-4 flex items-center justify-between">{{t('pages.custom_notifications.select_courses')}}
        <div>
          <button @click="selectAll" class="Btn Btn--Success">{{t('pages.custom_notifications.select_all_courses')}}</button>
          <button @click="removeAll" class="Btn Btn--Delete">{{ t('actions.remove_all_courses') }}</button>
        </div>
      </h4>

      <div class="flex flex-1 mb-4">
        <input v-model="search" type="text" :placeholder="t(`pages.courses.search_undefined`)" class="flex-1 mr-2"
          v-on:keyup.enter="getCoursesFromSearch" />
        <button @click="getCoursesFromSearch" class="Btn Btn--Save">{{ t('actions.search') }}</button>
      </div>
      <div v-if="loading" class="LoadingBox mt-4 text-center">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-else-if="error != ''" class="Box Box--Clear">
        <p class="text-danger">{{ error }}</p>
      </div>
      <div v-else>
        <div v-if="courses.length > 0">
          <ul class="List--Lined List--Lined--Thin">
            <li v-for="course in pageOfItems" :key="course.id" class="flex items-center justify-between">
              <span>{{ course.name }}</span>
              <button class="flex items-center" v-if="!course.selected" @click="addCourse(course)">
                <span class="mr-2 text-warning cursor-pointer">Non selezionato</span>
                <span class="Btn Btn--CheckStyle  Btn--CheckStyle--UnChecked "></span>
              </button>
              <button v-else class="flex items-center" @click="removeCourse(course)">
                <span class="mr-2 text-success cursor-pointer">Selezionato</span>
                <span class="Btn Btn--CheckStyle  Btn--CheckStyle--Checked">
                  <svg height="16" width="16" class="fill-current">
                    <use xlink:href="#icon-checkmark"></use>
                  </svg>
                </span>
              </button>
            </li>
          </ul>
          <div class="text-right mt-2">
            <button :disabled="saveDisabled" class="Btn Btn--Save" @click="openModal('saveChanges')">
              {{ t('actions.save_changes') }} {{ saveDisabled ? '' : `(${addedCourses.length + removedCourses.length} corsi)` }}
            </button>
          </div>
          <div class="text-center mt-2">
            <jw-pagination :items="courses" @changePage="onChangePage" :labels="customLabels"
              class="PaginationList"></jw-pagination>
          </div>
        </div>
        <div v-else class="mt-4 text-center">
          <p>{{ t('pages.courses.no_courses') }}</p>
        </div>
      </div>
    </div>

    <div class="Form--Boxed mb-8" v-if="isCustom"> 
      <div class="mb-4 flex items-center justify-between">
        <h4 class="TextTooltip">{{t('pages.users.add_users')}}
        </h4>
        <div>
          <button @click="openModal('enrollAll')" class="Btn Btn--Success">{{t('pages.users.add_users')}}</button> 
          <button @click="openModal('destroyAll')" class="Btn Btn--Delete">{{t('pages.users.remove_users')}}</button>
        </div>
      </div>
     
      <div class="field flex">
        <input v-model="search" v-on:keyup.enter="getUsers(true)" type="text" :placeholder="t('pages.users.search_user')" class="mr-2"/>
        <button class="Btn Btn--Save" @click="getUsers(true)">{{t('actions.search')}}</button>
      </div>

      <template v-if="allUsers.length > 0">
        <ul class="List--Lined List--Lined--Thin">
          <li v-for="user in allUsers" :key="user.id" class="flex items-center justify-between">
            <span v-if="!user.cnenrolled" @click="addUser(user)" class="ml-2 cursor-pointer">{{ user.first_name }} {{ user.last_name }} - {{fiscalCodeLogin ? user.fiscal_code : user.email }}</span>
            <span v-else @click="removeUser(user)" class="ml-2 cursor-pointer">{{ user.first_name }} {{ user.last_name }} - {{ fiscalCodeLogin ? user.fiscal_code : user.email }}</span>
            <button class="flex items-center" v-if="!user.cnenrolled"  @click="addUser(user)">
              <span class="mr-2 text-warning cursor-pointer">{{t('pages.users.not_recive_custom_notific')}}</span>
              <span class="Btn Btn--CheckStyle  Btn--CheckStyle--UnChecked "></span>
            </button>
            <button v-else class="flex items-center" @click="removeUser(user)">
              <span class="mr-2 text-success cursor-pointer">{{t('pages.users.recive_custom_notific')}}</span>
              <span class="Btn Btn--CheckStyle  Btn--CheckStyle--Checked">
                <svg height="16" width="16" class="fill-current"> 
                  <use xlink:href="#icon-checkmark"></use>
                </svg>
              </span>
            </button>
          </li>
        </ul> 
        <div class="text-right mt-2">
          <button v-if="addedUsers.length > 0 || removedUsers.length > 0" class="Btn Btn--Save" @click="openModal('addUsers')">{{t('actions.save_changes')}} ({{addedUsers.length+removedUsers.length}} {{t('global.users')}})</button>
        </div>
        <div class="text-center mt-2" >
          <paginate
            v-model="page"
            :page-count="pagy.pages"
            :click-handler="pageCallback"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'PaginationList'">
          </paginate>
        </div>
      </template>
      <template v-else-if="loading">
        <li class="LoadingBox">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </li>
      </template>
       <div v-else class="pt-8 text-center pb-8">
        <p class="text-lg">{{t('pages.users.no_users')}}</p>
      </div>
    </div>

    <div class="Form--Boxed mb-8" v-if="isCustom">
      <div class="mb-4 flex items-center justify-between">
        <h4>{{t('pages.groups.groups_management')}}</h4>
      </div>
      <template v-if="groups.length > 0">
        <ul class="List--Lined List--Lined--Thin">
          <li v-for="group in groups" :key="group.id" class="flex items-center justify-between">
            <span class="ml-2 cursor-pointer">{{ group.name }}</span>
            <button class="flex items-center" v-if="!group.cngenrolled" @click="addGroup(group)">
              <span class="mr-2 text-warning cursor-pointer">{{t('pages.users.not_recive_custom_notific')}}</span>
              <span class="Btn Btn--CheckStyle  Btn--CheckStyle--UnChecked "></span>
            </button>
            <button class="flex items-center" v-else @click="removeGroup(group)">
              <span class="mr-2 text-success cursor-pointer">{{t('pages.users.recive_custom_notific')}}</span>
              <span class="Btn Btn--CheckStyle  Btn--CheckStyle--Checked">
                <svg height="16" width="16" class="fill-current"> 
                  <use xlink:href="#icon-checkmark"></use>
                </svg>
              </span>
            </button>
          </li>
        </ul>
        <div class="text-right mt-2">
          <button v-if="addedGroups.length > 0 || removedGroups.length > 0" class="Btn Btn--Save" @click="openModal('addGroups')">{{t('actions.save_changes')}} ({{addedGroups.length + removedGroups.length}} {{t('global.groups')}})</button>
        </div>
        <div class="text-center mt-2">
          <paginate
            v-model="groupsPage"
            :page-count="groupsPagy.pages"
            :click-handler="groupsPageCallback"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'PaginationList'">
          </paginate>
        </div>
      </template>
      <template v-else-if="loadingGroups">
        <li class="LoadingBox">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </li>
      </template>
      <div v-else class="pt-8 text-center pb-8">
        <p class="text-lg">{{t('pages.groups.empty')}}</p>
      </div>
    </div>

    <div class="grid lg:grid-cols-2 gap-4">
      <!-- test the email -->
      <div class="Form--Boxed mb-8">
        <h4 class="mb-1">Test</h4>
        <p class="mb-4">{{t('pages.custom_notifications.invite_email_test')}}</p>
        <div class="flex flex-1 mb-4">
          <input type="email" placeholder="Email" v-model="testEmail" class="flex-1 mr-2" />
          <button @click="sendTestEmail" class="Btn Btn--Save">Invia mail di test</button>
        </div>
      </div>

      <!-- send the email -->
      <div class="Form--Boxed mb-8" v-if="isCustom">
        <h4 class="mb-1">{{t('pages.custom_notifications.send.title')}}</h4>
        <p class="mb-4">{{t('pages.custom_notifications.send.description')}}.</p>
        <div class="flex flex-1 mb-4 justify-end">
          <button @click="sendEmail" class="Btn Btn--Save">{{ t('actions.send') }}</button>
        </div>
      </div>

      <div class="Form--Boxed mb-8" v-if="!isCustom">
        <h4 class="mb-1">{{t('pages.custom_notifications.reset.title')}}</h4>
        <p class="mb-4">{{t('pages.custom_notifications.reset.description')}}</p>
        <div class="flex flex-1 mb-4 justify-end">
          <button @click="deleteSentNotifications" class="Btn Btn--Save">{{t('actions.send')}}</button>
        </div>
      </div>

    </div>

  
    <modal name="confirm-modal" height="auto">
      <div class="v--modal--header p-4">
        <h2 class="text-xl">{{ t('pages.course.editing') }}:</h2>
      </div>
      <div class="v--modal--body body--users p-4">
        <div v-if="addedCourses.length > 0" class="mb-4">
          <h4 class="mb-2">{{ t('pages.custom_notifications.courses_added') }}: ({{ addedCourses.length }})</h4>
          <ul class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
            <li v-for="course in addedCourses" :key="course.id">
              {{ course.name }}
            </li>
          </ul>
        </div>
        <div v-if="removedCourses.length > 0" class="mb-4">
          <h4 class="mb-2">{{ t('pages.custom_notifications.courses_removed') }}: ({{ removedCourses.length }})</h4>
          <ul v-if="removedCourses.length > 0" class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
            <li v-for="course in removedCourses" :key="course.id">
              {{ course.name }}
            </li>
          </ul>
        </div>
        <div v-if="addedUsers.length > 0" class="mb-4">
          <h4 class="mb-2">{{ t('pages.custom_notifications.users_added') }}: ({{ addedUsers.length }})</h4>
          <ul class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
            <li v-for="user in addedUsers" :key="user.id">
              {{ user.first_name }} {{ user.last_name }} - {{ user.email }}
            </li>
          </ul>
        </div>
        <div v-if="removedUsers.length > 0" class="mb-4">
          <h4 class="mb-2">{{ t('pages.custom_notifications.users_removed') }}: ({{ removedUsers.length }})</h4>
          <ul class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
            <li v-for="user in removedUsers" :key="user.id">
              {{ user.first_name }} {{ user.last_name }} - {{ user.email }}
            </li>
          </ul>
        </div>
        <div v-if="addedGroups.length > 0" class="mb-4">
          <h4 class="mb-2">{{ t('pages.groups.groups_added') }}: ({{ addedGroups.length }})</h4>
          <ul class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
            <li v-for="group in addedGroups" :key="group.id">
              {{ group.name }}
            </li>
          </ul>
        </div>
        <div v-if="removedGroups.length > 0" class="mb-4">
          <h4 class="mb-2">{{ t('pages.groups.groups_removed') }}: ({{ removedGroups.length }})</h4>
          <ul class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
            <li v-for="group in removedGroups" :key="group.id">
              {{ group.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="v--modal--actions sm:flex sm:justify-end p-4">
        <button class="Btn Btn--Save" @click="saveChanges">{{ t('actions.save') }}</button>
        <button class="Btn Btn--Edit ml-2" @click="$modal.hide('confirm-modal')">{{ t('actions.undo') }}</button>
      </div>
    </modal>

    <modal name="confirm-modal-action" height="auto">
      <div class="v--modal--header p-4">
        <h2 class="text-xl">{{t('pages.user.editing')}}:</h2>
      </div>
      <div class="v--modal--body p-4">
        <p>{{ modalType === 'enrollAll' ? t('pages.custom_notifications.confirm_invite_all') : t('pages.custom_notifications.confirm_remove_all') }}</p>
      </div>
      <div class="v--modal--actions sm:flex sm:justify-end p-4">
        <button class="Btn Btn--Save" @click="confirmAction">{{ t('actions.save') }}</button>
        <button class="Btn Btn--Edit ml-2" @click="$modal.hide('confirm-modal-action')">{{ t('actions.undo') }}</button>
      </div>
    </modal>

  </div>

</template>

<script>
import api from '../../../api'
import BackButton from '../../commons/backButton'
import ImgUpload from '../../commons/imgUpload'
import { mapState, mapActions, mapGetters } from 'vuex'
import { VueEditor } from "vue2-editor";
import { global as EventBus } from '../../../js/utils/eventBus';


export default {
  name: 'AdminCustomNotificationEdit',
  props: {
    customNotification: Object,
    languages: Array,
    notificationEvents: Array,
    users: Array,
    groups: Array
  },
  components: {
    BackButton,
    ImgUpload,
    VueEditor
  },
  data() {
    return {
      loading: false,
      page: 1,
      search: '',
      type: '',
      light: true,
      courses: [],
      error: '',
      pageOfItems: [],
      addedCourses: [],
      removedCourses: [],
      customLabels: {
        previous: '«',
        next: '»'
      },
      enabled: this.customNotification.enabled,
      params: {
        notification_type: this.customNotification.notification_type,
        delay: this.customNotification.delay,
        notification_event_id: this.customNotification.notification_event.id,
        frequency: this.customNotification.frequency
      },
      translations: this.customNotification.translations,
      testEmail: '',
      modalType: '',
      selectedLang: 'it', //take it from props,
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["code-block"],["link"], ["clean"]],
      users: [],
      groups: [],
      loadingGroups: false,
      groupsPage: 1,
      groupsPagy: {},
      addedGroups: [],
      removedGroups: [],
      initiallyEnrolledGroups: [],
      addedUsers: [],
      removedUsers: [],
      pageOfItems: [],
      allUsers: [],
      pagy: {},
    }
  },

  watch: {
  'params.notification_event_id': function(newValue) {
    if (newValue !== 3) {
      this.params.notification_type = 'after';
      console.log(newValue)
    }
  }
},

  computed: {

    ...mapState({
      allCourses: state => state.course.allCourses.sort((a, b) => (a.name > b.name) ? 1 : -1)
    }),
    
    isCustom() {
      return this.params.notification_event_id === 3
    },

    isNeverLoggedIn () {
      return this.params.notification_event_id === 4
    },

    isLiveStartingSoon() {
      return this.params.notification_event_id === 6
    },

    isEnabledChanged() {
      return this.enabled !== this.customNotification.enabled
    },
    userSaveDisabled() {
      return this.addedUsers.length === 0 && this.removedUsers.length === 0;
    },
    groupSaveDisabled() {
      return this.addedGroups.length === 0 && this.removedGroups.length === 0;
    }

  },
  mounted() {
    this.getUsers();
    this.getGroups();
    this.getCourses();
  },
  methods: {

    ...mapActions({
      getAllCourses: 'getAllCourses'
    }),

    getGroups() {
    this.loadingGroups = true;
    api.Group.getGroups({ page: this.groupsPage, custom_notification_id: this.customNotification.id })
      .then(res => {
        this.groups = res.groups.map(group => ({
          ...group,
          cngenrolled: group.cngenrolled || false
        }));
        this.groupsPagy = res.pagy;
        this.loadingGroups = false;
      })
      .catch(err => {
        this.loadingGroups = false;
        console.error("Error loading groups:", err);
      });
  },

  addGroup(group) {
      group.cngenrolled = true

      if (this.removedGroups.some(item => item.id === group.id)) {
        this.removedGroups = this.removedGroups.filter(obj => obj.id !== group.id)
      } else {
        this.addedGroups.push(group)
      }
    },
    removeGroup(group) {
      group.cngenrolled = false

      if (this.addedGroups.some(item => item.id === group.id)) {
        this.addedGroups = this.addedGroups.filter(obj => obj.id !== group.id)
      } else {
        this.removedGroups.push(group)
      }
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },

    pageCallback(pageNum){
      console.log(pageNum)
      if (pageNum != undefined) {
        this.page = pageNum
      }
      this.getUsers()
    },
    groupsPageCallback(pageNum) {
      if (pageNum != undefined) {
        this.groupsPage = pageNum;
      }
      this.getGroups();
    },

    getUsers(fromSearch){
      this.loading = true
      api.User.getUsersFromSearch({
        user_type: 'all', 
        page: fromSearch ? 1 : this.page,
        search: this.search,
        custom_notification_id: this.customNotification.id
      })
      .then( res => {
        this.allUsers = res.users.map(user => ({
        ...user,
        enrolled: user.cnenrolled
      }));
        this.pagy = res.pagy;
        console.log("Tutti gli utenti:", this.allUsers);
        this.loading = false
        this.addedUsers = [] 
        this.removedUsers = []
      })
      .catch(err => {
        this.loading = false
        console.log(err)
      })
    },

    addUser(user){
      user.cnenrolled = true
      // user was enrolled but added to removes user. doesn't have to be added
      if ( this.removedUsers.filter(item => item.id === user.id ).length > 0  ) {
        this.removedUsers = this.removedUsers.filter(function( obj ) {
          return obj.id !== user.id;
        });
      } else {
        this.addedUsers.push(user)
      }
    },
    removeUser(user){
      user.cnenrolled = false
      // user was not enrolled but added to added user. doesn't have to be removed
      if ( this.addedUsers.filter(item => item.id === user.id ).length > 0  ) {
        this.addedUsers = this.addedUsers.filter(function( obj ) {
          return obj.id !== user.id;
        });
      } else {
        this.removedUsers.push(user)
      }
      
    },

    getCourses() {
      this.loading = true

      api.CustomNotification.getCourses({
        search: this.search,
        id: this.customNotification.id
      }).then(res => {
        this.courses = res.sort((a, b) => (a.name > b.name) ? 1 : -1)
        this.loading = false
      }).catch(err => {
        this.error = err.message
        this.loading = false
      })
    },

    updateEnabled(enabled) {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      api.CustomNotification.update(this.customNotification.id, { enabled })
        .then(res => {
          this.customNotification = res;
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target })
        })
        .catch(err => {
          EventBus.emit('loader:local:end', { elem: target })
          this.$toastr.e(this.t('actions.error'));
        })
    },

    updateCustomNotification(params) {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      api.CustomNotification.update(this.customNotification.id, params)
        .then(res => {
          this.customNotification = res;
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target })
        })
        .catch(err => {
          EventBus.emit('loader:local:end', { elem: target })
          this.$toastr.e(this.t('actions.error'));
        })
    },

    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems;
    },
    onChangeGroupPage(pageOfItemsGroup) {
      this.pageOfItemsGroup = pageOfItemsGroup;
    },
    getCoursesFromSearch() {
      this.getCourses()
    },
    addCourse(course) {
      course.selected = true
      if (this.removedCourses.filter(item => item.id === course.id).length > 0) {
        this.removedCourses = this.removedCourses.filter(function (obj) {
          return obj.id !== course.id;
        });
      } else {
        this.addedCourses.push(course)
      }
    },
    removeCourse(course) {
      course.selected = false
      if (this.addedCourses.filter(item => item.id === course.id).length > 0) {
        this.addedCourse = this.addedCourses.filter(function (obj) {
          return obj.id !== course.id;
        });
      } else {
        this.removedCourses.push(course)
      }

    },
    openModal(modalType) {
      this.modalType = modalType
      if (modalType === 'enrollAll' || modalType === 'destroyAll') {
        this.$modal.show('confirm-modal-action');
      } else {
        this.$modal.show('confirm-modal');
      }
    },
    selectAll() {
      this.courses.forEach(el => {
        this.addCourse(el)
        this.allSelected = true
      })
      
    },
    removeAll() {
      this.courses.forEach(el => {
        this.removeCourse(el)
        this.allSelected = false
      })
    },

    async saveChanges() {
      const elem = event.currentTarget;
      EventBus.emit('loader:local:start', { elem });

      try {
        if (this.addedCourses.length > 0 || this.removedCourses.length > 0) {
          await this.saveCourses();
        }
        if (this.addedUsers.length > 0 || this.removedUsers.length > 0) {
          await this.saveUsers();
        }
        if (this.addedGroups.length > 0 || this.removedGroups.length > 0) {
          await this.saveGroups();
        }
        this.$modal.hide('confirm-modal');
        this.$toastr.s(this.t('actions.saved'));
      } catch (err) {
        this.$toastr.e(this.t('actions.error'));
      } finally {
        EventBus.emit('loader:local:end', { elem });
      }
    },

    async saveCourses() {
      //api call to create or remove notification_targets, passing course_ids and notification_id
      const elem = event.currentTarget
      EventBus.emit('loader:local:start', { elem })

      const createNotificationTargets = api.CustomNotification.createNotificationTargets(this.customNotification.id, { 
        targetable_ids: this.addedCourses.map(course => course.id),
        targetable_type: 'Course'
      })

      const deleteNotificationTargets = api.CustomNotification.deleteNotificationTargets(this.customNotification.id, { 
        targetable_ids: this.removedCourses.map(course => course.id),
        targetable_type: 'Course'
      })

      Promise.all([await createNotificationTargets, await deleteNotificationTargets])
        .then(res => {
          this.addedCourses = [];
          this.removedCourses = [];
          this.$modal.hide('confirm-modal');
          EventBus.emit('loader:local:end', { elem })
          // location.reload()
        }
      ).catch(err => {
        this.$toastr.e(this.t('errors.messages.try_again'));
        EventBus.emit('loader:local:end', { elem })
      })  
    },

    async saveUsers() {
      const elem = event.currentTarget;
      EventBus.emit('loader:local:start', { elem });

      try {
        if (this.addedUsers.length > 0) {
          console.log('Added Users:', this.addedUsers);
          console.log('Custom notification:', this.customNotification.id);

          await api.CustomNotification.addUsers(this.customNotification.id, { 
            custom_notification_id: this.customNotification.id,
            user_id: this.addedUsers.map(user => user.id)
          }).then(res => {
            console.log('Added Users Response:', res);
          }).catch(err => {
            console.log('Error Adding Users:', err);
          });
        }

        if (this.removedUsers.length > 0) {
          console.log('Removed Users:', this.removedUsers);
          console.log('Custom notification:', this.customNotification.id);

          await api.CustomNotification.removeUsers(this.customNotification.id, { 
            custom_notification_id: this.customNotification.id,
            user_id: this.removedUsers.map(user => user.id)
          }).then(res => {
            console.log('Removed Users Response:', res);
          }).catch(err => {
            console.log('Error Removing Users:', err);
          });
        }

        this.addedUsers = [];
        this.removedUsers = [];
        this.$modal.hide('confirm-modal');

      } catch (err) {
        this.$toastr.e(this.t('errors.messages.try_again'));
      } finally {
        EventBus.emit('loader:local:end', { elem });
      }
    },

    async saveGroups() {
      const addGroups = api.CustomNotification.addGroups(this.customNotification.id, {
        custom_notification_id: this.customNotification.id,
        group_id: this.addedGroups.map(group => group.id)
      });

      const removeGroups = api.CustomNotification.removeGroups(this.customNotification.id, {
        custom_notification_id: this.customNotification.id,
        group_id: this.removedGroups.map(group => group.id)
      });

      Promise.all([await addGroups, await removeGroups])
        .then(res => {
          this.addedGroups = [];
          this.removedGroups = [];
          this.$modal.hide('confirm-modal');
        })
        .catch(err => {
          this.$toastr.e(this.t('errors.messages.try_again'));
        });
    },

    sendTestEmail() {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      api.CustomNotification.sendTestEmail(this.customNotification.id, {
        email: this.testEmail,
         //first 3 courses of this.courses
        course_ids: this.courses.slice(0, 3).map(course => course.id)
      })
        .then(res => {
          this.$toastr.s('Email inviata con successo')
          EventBus.emit('loader:local:end', { elem: target })
        })
        .catch(err => {
          this.$toastr.e('Errore nell\'invio dell\'email')
          EventBus.emit('loader:local:end', { elem: target })
        })
    },

    sendEmail() {
      if (confirm( `You are about to send an email to all users. Are you really sure?` )){
         //send email to all users
        const target = event.currentTarget;
        EventBus.emit('loader:local:start', { elem: target })
        api.CustomNotification.sendEmail(this.customNotification.id)
          .then(res => {
            this.$toastr.s('Email inviata con successo')
            EventBus.emit('loader:local:end', { elem: target })
          })
          .catch(err => {
            this.$toastr.e('Errore nell\'invio dell\'email')
            EventBus.emit('loader:local:end', { elem: target })
          })
      }
    },

    updateTranslation(translation) {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      api.CustomNotification.updateTranslation(this.customNotification.id, {
        translation_id: translation.id,
        subject: translation.subject,
        body: translation.body
      })
        .then(res => {
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target })
        })
        .catch(err => {
          EventBus.emit('loader:local:end', { elem: target })
          this.$toastr.e(this.t('actions.error'));
        })
    },

    deleteSentNotifications() {
      if (confirm( `You are about to reset the notification to all users. Are you really sure?` )){

        const target = event.currentTarget;
        EventBus.emit('loader:local:start', { elem: target })
        api.CustomNotification.deleteSentNotifications(this.customNotification.id)
          .then(res => {
            this.$toastr.s(this.t('actions.saved'));
            EventBus.emit('loader:local:end', { elem: target })
          })
          .catch(err => {
            EventBus.emit('loader:local:end', { elem: target })
            this.$toastr.e(this.t('actions.error'));
          })
      }    
    },

    confirmAction() {
    const customNotificationId = this.customNotification.id;
    if (this.modalType === 'enrollAll') {
      this.addUsers(this.customNotification.id, [], true);
    } else if (this.modalType === 'destroyAll') {
      this.removeUsers(this.customNotification.id, [], true);
    }
    this.$modal.hide('confirm-modal-action');
  },

  async addUsers(customNotificationId, userIds = [], enrollAll = false) {
    try {
      await api.CustomNotification.addUsers(customNotificationId, { user_id: userIds, enroll_all: enrollAll });
      this.$toastr.s('Users added successfully');
      window.location.reload();
    } catch (error) {
      this.$toastr.e('Failed to add users');
    }
  },

  async removeUsers(customNotificationId, userIds = [], destroyAll = false) {
    try {
      await api.CustomNotification.removeUsers(customNotificationId, { user_id: userIds, destroy_all: destroyAll });
      this.$toastr.s('Users removed successfully');
      window.location.reload();
    } catch (error) {
      this.$toastr.e('Failed to remove users');
    }
  }

  }
}

</script>
<style lang="scss">

#cn-lang {
  text-transform: uppercase;
  max-width: 200px;
}

.ToggleContainer {
  height: 40px;
}

</style>