<template>
  <div>
    <div class="flex items-center  flex-wrap justify-between mb-4">
      <BackButton :href="`/admin/courses/${enroll.course.id}/stats`" :cssClass="'mr-4'" />
       <p class="flex-1 font-bold text-lg pr-4">{{enroll.course.name}} - {{enroll.user.first_name}} {{enroll.user.last_name}} </p> 
       <button   @click="exportStats" :disabled="loadingStats" class="Btn Btn--Save">{{t('actions.export_stats')}}</button>
     </div>
    <div class="Box Box--Clear mb-4">
      <div class="sm:grid grid-cols-2 gap-2">
        <div class="mb-4">
          <p class="text-lg mb-1"><strong>Utente:</strong></p>
          <p>{{enroll.user.first_name}} {{enroll.user.last_name}} - {{this.fiscalCodeLogin ? this.enroll.user.fiscal_code : this.enroll.user.email}}</p>
        </div>
         <div class="mb-4">
          <p  class="text-lg mb-1"><strong>Corso:</strong></p>
          <p>{{enroll.course.name}}</p>
        </div>
        <div>
          <p  class="text-lg mb-1"><strong>Completamento:</strong></p>
          <span v-if="enroll.progress_status"><strong>{{ t(`pages.course.${enroll.progress_status}`) }}</strong></span>
          <div class="flex items-center" v-else> 
            <ProgressBar :progress="enroll.percentageCompleted / 100" />
            <span class="ml-2 font-bold">{{ `${ enroll.percentageCompleted }%` }}</span>
          </div>
          
        </div>
         <div>
          <p  class="text-lg mb-1"><strong>Ore fruite:</strong></p>
          <p  v-if="!loadingStats" >{{totDuration(totHours) }}</p>
        </div>
      </div>
    </div>

    <div class="Box Box--Clear mb-2">
       <p class="text-lg mb-2"><strong>Sessioni:</strong></p>
       <div v-if=" lectureActivities.length > 0">
        <table class="Table table-auto"  >
          <thead>
            <tr>
              <th class="text-left">Orario di accesso</th>
              <th class="text-left">Orario di uscita</th>
              <th class="text-left">Durata</th>
              <th class="text-left">Titolo lezione</th>
              <th v-if="enroll.course.path" class="text-left">Titolo corso</th>
            </tr>
          </thead>
          <tbody class="List">
            <tr v-for="activity in lectureStatsList(lectureActivities)" :key="activity.id">
              <td class="whitespace-no-wrap">
                <input  v-if="editingId === activity.id" v-model="activity.from" type="text" />
                <span v-else> {{ activity.from }}</span>
              </td>
              <td class="whitespace-no-wrap">
                <input  v-if="editingId === activity.id" v-model="activity.to" type="text" />
                <span v-else> {{ activity.to }}</span>
              </td>
              <td>
                <input  v-if="editingId === activity.id" v-model="activity.duration" type="text" />
                <span v-else> {{ activity.duration }}</span>
              </td>
              <td>{{activity.title}}</td>
              <td v-if="enroll.course.path">{{activity.course_name}}</td>
              <button  class="Btn Btn--Save" v-if="editingId === activity.id" @click="saveItem(activity)">Salva</button>
              <button  class="Btn Btn--Edit" v-else-if="isLacerbaAdmin" @click="editItem(activity.id)">Modifica</button>
            </tr>
          </tbody>
        </table>
        <div class="text-center mt-2">
          <paginate
            v-model="lPage"
            :page-count="lPagy.pages"
            :click-handler="lPageCallback"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'PaginationList'">
          </paginate>
        </div>
      </div>
       <p v-else>
         Non sono presenti sessioni
       </p>
    </div>
    <!-- tmp per scorm-->
    <div class="Box Box--Clear" v-if=" quizActivities.length > 0">
      <p class="text-lg mb-2"><strong>Quiz:</strong></p>
      <div v-if=" quizActivities.length > 0">
        <table class="Table table-auto" >
          <thead>
            <tr>
              <th class="text-left">Orario di accesso</th>
              <th class="text-left">Orario di uscita</th>
              <th class="text-left">Risultato</th>
              <th class="text-left">Titolo lezione</th>
              <th v-if="enroll.course.path" class="text-left">Titolo corso</th>
            </tr>
          </thead>
          <tbody class="List">
            <tr v-for="activity in quizList(quizActivities)" :key="activity.id">
              <td class="whitespace-no-wrap">
                <input  v-if="editingId === activity.id" v-model="activity.from" type="text" />
                <span v-else> {{ activity.from }}</span>
              </td>
              <td class="whitespace-no-wrap">
                <input  v-if="editingId === activity.id" v-model="activity.to" type="text" />
                <span v-else> {{ activity.to }}</span>
              </td>
              <td>{{quizResult(activity.result)}}</td>
              <td>{{activity.title}}</td>
              <td v-if="enroll.course.path">{{activity.course_name}}</td>
              <button  class="Btn Btn--Save" v-if="editingId === activity.id" @click="saveItem(activity)">Salva</button>
              <button  class="Btn Btn--Edit" v-else-if="isLacerbaAdmin" @click="editItem(activity.id)">Modifica</button>
            </tr>
          </tbody>
        </table>
        <div class="text-center mt-2">
          <paginate
            v-model="qPage"
            :page-count="qPagy.pages"
            :click-handler="qPageCallback"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'PaginationList'">
          </paginate>
        </div>
      </div>
       <p v-else>
         Non sono presenti quiz
       </p>
    </div>

  </div>
</template>

<script>
  import ProgressBar from '../../commons/progressBar.vue'
  import BackButton from '../../commons/backButton'
  import { map, chunk, defaultTo } from "lodash";
  import api from  '../../../api'
  //import * as XLSX from 'xlsx';
  import * as XLSX from 'xlsx-js-style';
  import moment from "moment";
import page from '../../../api/page';



  export default {
    props: {
      enroll: Object,
      tenant_name: String,
      fiscalCodeLogin: Boolean,
      isLacerbaAdmin: Boolean,
      courseSettings: Array,
    },
    components: {
      ProgressBar,
      BackButton
    },
    data() {
      return {
        lectureActivities: [],
        quizActivities: [],
        courseDetailsActivities: [],
        lPage: 1,
        qPage: 1,
        lPagy: {},
        qPagy: {},
        loadingStats: true,
        totHours: 0,
        editingId: null,
      }
    },
    mounted() {
      this.getLectureActivities()
      this.getQuizActivities()
      this.getLectureActivities(true)
      this.getQuizActivities(true)
      this.getCourseDetailsInfo(true)
      this.getDailyHours()

   

    },
    computed: {
      projectInfo() {
        return [{
          course_name: this.enroll.course.name,
          user: `${this.enroll.user.first_name} ${this.enroll.user.last_name} - ${this.fiscalCodeLogin ? this.enroll.user.fiscal_code : this.enroll.user.email}`,
          percentage: `${ this.enroll.percentageCompleted }%`,
          tot_hours: this.totDuration(this.totHours),
          start_date:this.enroll.course.start_date ?  moment(this.enroll.course.start_date).format('DD/MM/YYYY'): '',
          ...(this.id_anpal ? { id_anpal: this.id_anpal } : {}),
          ...(this.id_piano_fondimpresa ? { id_piano_fondimpresa: this.id_piano_fondimpresa } : {}),
        }]
      },

      id_anpal() {
        return this.courseSettings.filter(el => el.name === 'id_anpal')[0]?.return_value
      },

      id_piano_fondimpresa() {
        return this.courseSettings.filter(el => el.name === 'id_piano_fondimpresa')[0]?.return_value
      },


    },
    methods: {


      quizResult(result) {
        //i.e, quiz-completed-PASS-score-100% needs to be: PASSED-100%
        const resultArr = result.split('-')
        const resultType = resultArr[2]
        const resultScore = resultArr[4]
        return `${resultType}-${resultScore}`
        
      },

      sessionDuration(sec) {
        return new Date(defaultTo( sec, 0) * 1000).toISOString().substr(11, 8)
      },

      totDuration(totSecs, withSecs) {
        let totalSeconds = totSecs || 0
        const hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        if (withSecs) {
          return `${hours}:${minutes}:${seconds}`
        } else {
          return `${hours}h: ${minutes}m `
        }
          
      },

      lectureStatsList(lectureActivities) { 
        return lectureActivities.map(el => {
            return {
              //TODO: date_session and time_session: if live, start_date of the session, else same of from
              date_session: el.start_live? moment(el.start_live).format('DD/MM/YYYY') : el.start_datetime ? moment(el.start_datetime).format('DD/MM/YYYY') : 'No date',
              time_session: el.start_live ? moment(el.start_live).format('HH:mm:ss') : el.start_datetime ? moment(el.start_datetime).format('HH:mm:ss') : 'No date',
              from: el.start_datetime ? moment(el.start_datetime).format('DD/MM/YYYY - HH:mm:ss') : 'No date',
              to:  el.end_datetime ? moment(el.end_datetime).format('DD/MM/YYYY - HH:mm:ss') : 'No date',
              duration: new Date(defaultTo( el.duration, 0) * 1000).toISOString().substr(11, 8),
              title: el.title,
              course_name: el.course_name,
              id: el.id
            }
        })
      },
    

      quizList(quizActivities) {
        //Come visualizzare i quiz? 
        return quizActivities.map(activity => {
          return {
                from: moment(activity.start_datetime).format('DD/MM/YYYY - HH:mm:ss'),
                to:  moment(activity.end_datetime).format('DD/MM/YYYY - HH:mm:ss'),
                result: activity.activity_type,
                title: activity.title,
                course_name: activity.course_name,
                id: activity.id,
            };
  
        });
      },

      courseDetailsInfo(courseDetailsActivities) {
        return courseDetailsActivities.map(el => {
          return {
              path_name: el.path_name,
              course_name: el.course_name,
              last_completed_lecture: el.last_completed_lecture,
              first_not_completed_lecture: el.first_not_completed_lecture,
              first_lecture_not_completed_point: el.first_lecture_not_completed_point,
              status: el.status
            }
        })
        
      },

      dailyHoursList() {
        return [
          {
            date: moment().format('DD/MM/YYYY'),
            duration: this.totDuration(500, true)
          },
          {
            date: moment().subtract(1, 'days').format('DD/MM/YYYY'),
            duration: this.totDuration(5000, true)
          }
      ]
      },

      exportStats() {
        const  wb = XLSX.utils.book_new()

        const sheets = [
          {
            heading: [ [
              { v: this.enroll.course.path ? "Percorso" : "Corso", t: "s", s: { font: { bold: true } } },
              { v: "Utente", t: "s", s:{ font: {  bold: true } } },
              { v: "Completamento", t: "s", s: { font: {  bold: true } } },
              { v: "Ore totali", t: "s", s:{ font: {  bold: true } } },
              { v: this.enroll.course.start_date ? "Data inizio attività" : '', t: "s", s:{ font: {  bold: true } } },
              ...(this.id_anpal ? [{ v: "ID ISTANZA ANPAL", t: "s", s:{ font: {  bold: true } } }] : []),
              ...(this.id_piano_fondimpresa ? [{ v: "ID FONDIMPRESA", t: "s", s:{ font: {  bold: true } } }] : []),
            ]],
            ws: XLSX.utils.json_to_sheet([]),
            arr: this.projectInfo,
            name:  "Info generali"
          },
          {
            heading: [ [
              { v: "Data sessione", t: "s", s: { font: { bold: true } } },
              { v: "Orario sessione", t: "s", s:{ font: {  bold: true } } },
              { v: "Orario di accesso", t: "s", s: { font: { bold: true } } },
              { v: "Orario di uscita", t: "s", s:{ font: {  bold: true } } },
              { v: "Durata", t: "s", s: { font: {  bold: true } } },
              { v: "Titolo lezione", t: "s", s:{ font: {  bold: true } } },
              { v: "Titolo Corso", t: "s", s:{ font: {  bold: true } } },
              { v: "ID", t: "s", s:{ font: {  bold: true } } },

            ]],
            ws: XLSX.utils.json_to_sheet([]),
            arr: this.lectureStatsList(this.allLectureActivities),
            name: "Sessioni"
          },
          {
            heading: [ [
              { v: "Data", t: "s", s:{ font: {  bold: true } } },
              { v: "Durata (tot. giornaliero)", t: "s", s: { font: {  bold: true } } },
              { v: "Durata netta (tot. giornaliero)", t: "s", s: { font: {  bold: true } } },
            ]],
            ws: XLSX.utils.json_to_sheet([]),
            arr: this.dailyHours,
            name: "Totale formazione"
          },
          {
            heading: [ [
              { v: "Orario di accesso", t: "s", s: { font: { bold: true } } },
              { v: "Orario di uscita", t: "s", s:{ font: {  bold: true } } },
              { v: "Risultato", t: "s", s: { font: {  bold: true } } },
              { v: "Titolo lezione", t: "s", s:{ font: {  bold: true } } },
              { v: "Titolo Corso", t: "s", s:{ font: {  bold: true } } },
              { v: "ID", t: "s", s:{ font: {  bold: true } } },
            ]],
            ws: XLSX.utils.json_to_sheet([]),
            arr: this.quizList(this.allQuizActivities),
            name: "Quiz"
          },
          {
            heading: [ [
              { v: this.enroll.course.path ? "Percorso" : "Corso", t: "s", s: { font: { bold: true } } },
              { v: "Corso", t: "s", s:{ font: {  bold: true } } },
              { v: "Ultima lezione completata", t: "s", s: { font: {  bold: true } } },
              { v: "Eventuale lezione iniziata ma non completata", t: "s", s: { font: {  bold: true } } },
              { v: "Punto raggiunto lezione non completata", t: "s", s:{ font: {  bold: true } } },
              { v: "Stato lezioni", t: "s", s:{ font: {  bold: true } } },
            ]],
            ws: XLSX.utils.json_to_sheet([]),
            arr: this.courseDetailsInfo(this.courseDetailsActivities),
            name: "Dettagli corsi"
          }

        ]

        sheets.forEach(el => {
          XLSX.utils.sheet_add_aoa(el.ws, el.heading);
          XLSX.utils.sheet_add_json(el.ws, el.arr, { origin: 'A2', skipHeader: true });
          XLSX.utils.book_append_sheet(wb, el.ws, el.name)
        })

        XLSX.writeFile(wb, `Report_${this.enroll.user.first_name}_${this.enroll.user.last_name}_${this.enroll.course.id}.xlsx`)
        
      },


      getLectureActivities(noPagy) {
        api.UserActivity.getLectureActivities({
          course_id: this.enroll.course.id,
          user_id: this.enroll.user.id,
          page: this.lPage, 
          noPagy: noPagy
        })
          .then(res => {
            if (noPagy) {
              this.allLectureActivities = res.lecture_activities
              this.totHours = res.duration
              this.loadingStats = false
            } else {
              this.lectureActivities = res.lecture_activities
              this.lPagy = res.pagy
            }
          })
      },
      getQuizActivities(noPagy) {
        api.UserActivity.getQuizActivities({
          course_id: this.enroll.course.id,
          user_id: this.enroll.user.id,
          page: this.qPage, 
          noPagy: noPagy
        })
          .then(res => {
            if (noPagy) {
                this.allQuizActivities = res.quiz_activities
            } else {
               this.quizActivities = res.quiz_activities
               this.qPagy = res.pagy
            }
           
          })
      },

      getCourseDetailsInfo() {
        api.UserActivity.getCourseDetailsInfo({
          course_id: this.enroll.course.id,
          user_id: this.enroll.user.id
        })
          .then(res => {
            this.courseDetailsActivities = res
          })
      },

      getDailyHours() {
        api.UserActivity.getDailyHours({
          course_id: this.enroll.course.id,
          user_id: this.enroll.user.id
        })
          .then(res => {
            this.dailyHours = res
          })
      },


      lPageCallback(pageNum){
        if (pageNum != undefined) {
          this.lPage = pageNum
        }
        this.getLectureActivities()
      },
      qPageCallback(pageNum){
        console.log(pageNum)
        if (pageNum != undefined) {
          this.qPage = pageNum
        }
        this.getQuizActivities()
      },

      editItem(id) {
        this.editingId = id;
      },
      saveItem(activity) {
        console.log(activity)
        //transform "00:00:00" to seconds
        if (activity.duration) {
          const durationArr = activity.duration.split(':')
          const durationSecs = (+durationArr[0]) * 60 * 60 + (+durationArr[1]) * 60 + (+durationArr[2])
          activity.duration = durationSecs
        } else {
          activity.duration = null
        }
         
        api.UserActivity.updateUserActivity({
          id: activity.id,
          start_datetime: activity.from,
          end_datetime: activity.to,
          duration: activity.duration
        })
          .then(res => {
            this.$toastr.s(this.t('actions.saved'));
            //location.reload()
          })
          .catch(err => {
            this.$toastr.e(this.t('actions.error'));
          })

        this.editingId = null;
      }
    }
  }
</script>