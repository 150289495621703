import api from "./instance";

const getWidgets = () =>
  api.get("/admin/homepage_widgets.json").then((response) => response.data);

const update = (params) =>
  api
    .put(`/admin/homepage_widgets/${params.widget_key}`, params)
    .then((response) => response.data);

export default {
  update,
  getWidgets,
};
