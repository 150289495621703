<template>
  <div class="mb-6">
    <div class="flex items-center justify-between  mt-6 mb-2">
      <h2 class="text-xl">{{canEdit ? t('actions.add_lectures') : t('global.lectures.p')}}</h2>  
    </div>
   
    <!-- TODO: selezionare il corso scormcloud, associando l'id scorm con l'external_course_id  -->
    <div class="Form--Boxed mb-4" v-if="scormEnabled && !course.path && canEdit">
        <p class="font-bold mb-4 text-lg">{{t('pages.course.add_scorm')}}</p>
        <div class="mb-4">
          <file-upload
            ref="uploadZip"
            v-model="scormFile"
            post-action="/admin/scorm_cloud/create_course"
            accept=".zip"
            :data="{course_id: course.id, content_type: 'scorm'}"
            :drop="true"
            :thread="100"
            @input-file="inputFileZip"
            input-id="uploadZip"
          >
          {{t('pages.course.upload_scorm')}}
          </file-upload>
          
          <div v-if="scormFile.length > 0 && scormFile[0].data.content_type == 'scorm'">
            <div class=" border-l-4 border-warning text-warning text-orange-700 px-4 py-1" role="alert">
              <p class="font-bold">{{t('pages.course.scorm_loading.title')}}</p>
              <p>{{t('pages.course.scorm_loading.subtitle')}}</p>
            </div>
          </div>
          <!-- TODO: far vedere che il corso è stato caricato correttamente
          <div class="mt-2">
            <div class="flex items-center gap-2">
              <p>Corso caricato: <strong>{{scormCourse.course.title}}</strong></p>
              <a class="Btn Btn--Save Btn--Icon" target="_blank" :href="scormCourse.preview_link.launchLink">
                <svg height="16" width="16" class="fill-current"> 
                  <use xlink:href="#icon-view-show"></use>
                </svg>
              </a>
            </div>
          </div>
        -->
        </div>

        <button
          :class="{Btn: true, 'Btn--Save': !addExistingScorm, 'Btn--Delete': addExistingScorm }"
          @click="toggleScormSection('addExistingScorm')" >
          {{ !scormSections.addExistingScorm ? t('pages.course.select_existing_scorm') : t('pages.course.close_add_existing_scorm')}}
        </button>

        <div v-if="scormSections.addExistingScorm" class="Form--InnerSection mt-6">
          
          <p class="mb-2">
            {{t('pages.course.create_existing_scorm_instruction')}}
          </p>
          <div class="field">
            <v-select
              :placeholder="t('pages.course.select_existing_scorm')"
              :options="scormCoursesOptions"
              @input="selection => updateSelectedScorm(selection)"
              class="mb-2  max-w-xl"
            />
          </div>
          <p v-if="createError['existing_scorm']" class="text-danger mb-2">{{t('actions.error')}}</p>
          <button
            :class="['Btn Btn--Save', {loading: buttonsLoading['existing_scorm']}]"
            @click="createLecture(newExistingScorm, 'existing_scorm')"
            :disabled="newExistingScorm.external_scorm_id == 0"
          >
            {{t('actions.save')}}
          </button>
        </div>
  
    </div>

   
    <div class="Form--Boxed mb-4" v-if="canEdit">
      <p class="font-bold mb-4 text-lg">{{t('pages.course.upload_videos')}}</p>
      <div v-for="(file, index) in files" :key="index">
        <div class="progress" v-if="file.active">
          <progressBar :progress="1" :indeterminate="true" />
          <!-- <div :style="{width: file.progress + '%'}">{{file.progress}}%</div> -->
        </div>
      </div>
      <div>
        <file-upload
            ref="uploadVimeo"
            v-model="files"
            post-action="/admin/contents"
            :chunk-enabled="true"
            :chunk="{
              action: '/admin/contents/upload_video',
              minSize: 50 * 1048576,
              maxActive: 1,
              maxRetries: 5,

              startBody: {
                course_id: course.id,
                content_type: 'vimeo_chunk'
              },

            }"

            accept="video/*"
            :data="{course_id: course.id, content_type: 'vimeo'}"
            :multiple="true"
            :drop="true"
            :thread="100"
            @input-file="inputFile"
            input-id="uploadVimeo"
          >
         {{t('pages.course.upload_videos')}} (max. 10 per bulk)
        </file-upload>
      </div>
    </div>
    
      <div class="Form--Boxed mb-4" v-if="canEdit">
        <p class="font-bold mb-4 text-lg">{{ t('pages.course.add_single_lectures') }}</p>
        <button 
          :class="{Btn: true, 'Btn--Save': !addNewSection, 'Btn--Delete': addNewSection }"
          @click="addNewSection = !addNewSection, addNewLecture = false, addNewQuiz = false, showLiveOptions = false" >
          {{ !addNewSection ? t('pages.course.add_section') : t('pages.course.close_add_section')}}
        </button> 
        <button 
          :class="{Btn: true, 'Btn--Save': !addNewLecture, 'Btn--Delete': addNewLecture }"
          @click="[addNewLecture = !addNewLecture, addNewSection = false, addNewQuiz = false, showLiveOptions = false]" >
          {{ !addNewLecture ? t('pages.course.add_lecture') :  t('pages.course.close_add_lecture')}}
        </button> 
        <button 
          :class="{Btn: true, 'Btn--Save': !addNewQuiz, 'Btn--Delete': addNewQuiz }"
          @click="[addNewQuiz = !addNewQuiz, addNewLecture = false, addNewLecture = false, showLiveOptions = false]" >
          {{ !addNewQuiz ? t('pages.course.add_quiz') :  t('pages.course.close_add_quiz')}}
        </button> 
        <button  v-if="features.lives"
          :class="{Btn: true, 'Btn--Save': !showLiveOptions, 'Btn--Delete': showLiveOptions}"
          @click="toggleLiveOptions()">
          {{ !showLiveOptions ? t('pages.course.add_live') : t('pages.course.close_add_live') }}
        </button>
        <button
          :class="{Btn: true, 'Btn--Save': !showGoogleFormOptions, 'Btn--Delete': showGoogleFormOptions}"
          @click="toggleGoogleFormOptions">
          {{ !showGoogleFormOptions ? t('pages.course.add_survey') : t('pages.course.close_add_survey') }}
        </button>


        <div v-if="addNewSection && !addNewLecture && !addNewQuiz" class="Form--InnerSection mt-6">
          <div class="field">
            <label class="mb-2">{{t('pages.course.create_section')}}</label>
            <input 
              v-model="newSection.name"
              @keyup.enter="createLecture(newSection)"
              class="appearance-none bg-transparent max-w-xl border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
              type="text" 
              :placeholder="t('pages.course.section_name')" 
            />
            </div>
          <p v-if="createError['section']" class="text-danger">{{t('actions.error')}}</p>
          <button 
             :class="['Btn Btn--Save mt-4', {loading: buttonsLoading['section']}]"
            @click="createLecture(newSection)" >
            {{t('actions.save')}}
          </button> 
        </div>

        <div v-if="addNewLecture" class="Form--InnerSection mt-6">
          <div class="field">
            <label class="mb-2">{{t('pages.course.create_lecture')}}</label>
            <input 
              v-model="newLecture.name"
              @keyup.enter="createLecture(newLecture)"
              class="appearance-none bg-transparent max-w-xl border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
              type="text" 
              :placeholder="t('pages.course.lecture_name')" 
            />
          </div>
          <p v-if="createError['lecture']" class="text-danger">{{t('actions.error')}}</p>
          <button 
            :class="['Btn Btn--Save mt-4', {loading: buttonsLoading['lecture']}]"
            @click="createLecture(newLecture)" >
            {{t('actions.save')}}
          </button> 
        </div>

        <div v-if="addNewQuiz" class="Form--InnerSection mt-6">
          <div class="field">
            <label class="mb-2">{{t('pages.course.create_quiz')}}</label>
            <input 
              v-model="newQuiz.name"
              @keyup.enter="createQuiz(newQuiz)"
              class="appearance-none  bg-transparent max-w-xl border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
              type="text" 
              :placeholder="t('pages.course.quiz_name')" 
            />
            </div>
          <p v-if="createError['quiz']" class="text-danger">{{t('actions.error')}}</p>
          <button 
            :class="['Btn Btn--Save mt-4', {loading: buttonsLoading['quiz']}]"
            @click="createQuiz(newQuiz)" >
            {{t('actions.save')}}
          </button>  
        </div>

        <div v-if="showLiveOptions && !addNewLecture && !addNewQuiz && !addNewSection" class="mt-3">
          <button 
            :class="['mr-1', {Btn: true, 'Btn--Save_zoom': !showZoomSection, 'Btn--Delete_zoom': showZoomSection}]"
            @click="toggleZoomSection()">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48" style="margin-right: 5px;">
              <circle cx="24" cy="24" r="20" fill="#2196f3"></circle><path fill="#fff" d="M29,31H14c-1.657,0-3-1.343-3-3V17h15c1.657,0,3,1.343,3,3V31z"></path><polygon fill="#fff" points="37,31 31,27 31,21 37,17"></polygon>
            </svg>
            {{ !showZoomSection ? 'Zoom' : t('actions.close') + ' Zoom' }}
          </button>
          <button 
            :class="{Btn: true, 'Btn--Save_teams': !showTeamsSection, 'Btn--Delete_teams': showTeamsSection}"
            @click="toggleTeamsSection()">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48" style="margin-right: 5px;">
              <path fill="#5059c9" d="M44,22v8c0,3.314-2.686,6-6,6s-6-2.686-6-6V20h10C43.105,20,44,20.895,44,22z M38,16	c2.209,0,4-1.791,4-4c0-2.209-1.791-4-4-4s-4,1.791-4,4C34,14.209,35.791,16,38,16z"></path>
              <path fill="#7b83eb" d="M35,22v11c0,5.743-4.841,10.356-10.666,9.978C19.019,42.634,15,37.983,15,32.657V20h18	C34.105,20,35,20.895,35,22z M25,17c3.314,0,6-2.686,6-6s-2.686-6-6-6s-6,2.686-6,6S21.686,17,25,17z"></path>
              <circle cx="25" cy="11" r="6" fill="#7b83eb"></circle>
              <path d="M26,33.319V20H15v12.657c0,1.534,0.343,3.008,0.944,4.343h6.374C24.352,37,26,35.352,26,33.319z" opacity=".05"></path>
              <path d="M15,20v12.657c0,1.16,0.201,2.284,0.554,3.343h6.658c1.724,0,3.121-1.397,3.121-3.121V20H15z" opacity=".07"></path>
              <path d="M24.667,20H15v12.657c0,0.802,0.101,1.584,0.274,2.343h6.832c1.414,0,2.56-1.146,2.56-2.56V20z" opacity=".09"></path>
              <linearGradient id="DqqEodsTc8fO7iIkpib~Na_zQ92KI7XjZgR_gr1" x1="4.648" x2="23.403" y1="14.648" y2="33.403" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#5961c3"></stop>
                <stop offset="1" stop-color="#3a41ac"></stop>
              </linearGradient>
              <path fill="url(#DqqEodsTc8fO7iIkpib~Na_zQ92KI7XjZgR_gr1)" d="M22,34H6c-1.105,0-2-0.895-2-2V16c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2v16	C24,33.105,23.105,34,22,34z"></path>
              <path fill="#fff" d="M18.068,18.999H9.932v1.72h3.047v8.28h2.042v-8.28h3.047V18.999z"></path>
            </svg>
            {{ !showTeamsSection ? 'Microsoft Teams' : t('actions.close') + ' Microsoft Teams' }}
          </button>
        </div>

        <div v-if="showZoomSection && showLiveOptions  && !addNewLecture && !addNewQuiz && !addNewSection">
          <div :class="['mt-6', {'Form--InnerSection': zoomUsersArray > 0}]">
            <template v-if="zoomAuthError">
              <div class="border-l-4 border-danger text-danger text-daner-700 px-4 py-1" role="alert">
                <p>{{t('pages.course.zoom_error.one')}}</p>
                <p>{{t('pages.course.zoom_error.two')}} <a target="_blank" href="/admin/academy_settings#/zoom" class="Btn Btn--Text">{{t('global.settings')}}</a></p>
              </div>
            </template>
            <template v-else-if="zoomUsersArray.length == 0">
              <div class=" border-l-4 px-4 py-1" role="alert">
                <p>{{t('pages.course.zoom_missing.one')}}</p>
                <p>{{t('pages.course.zoom_missing.two')}} <a target="_blank" href="/admin/academy_settings#/zoom" class="Btn Btn--Text">{{t('global.settings')}}</a></p>
              </div>
            </template>
            <template v-else>
            <label>{{t('pages.course.create_live')}}</label>
              <p v-if="liveType === 'zoom'" class="mb-2">
                {{t('pages.course.create_zoom_instruction', [newLive.event_type])}}
              </p>
              <p class="text-danger mt-2" v-if="error != ''">{{error}}</p>
              <div class="field field--inline flex items-center gap-3" v-if="features.webinar">
                <div class="flex items-center gap-1">
                  <input type="radio" id="one" value="meeting" v-model="newLive.event_type" />
                    <label for="one" class="mb-0">Meeting</label>
                </div>
                <div class="flex items-center gap-1">
                  <input type="radio" id="two" value="webinar" v-model="newLive.event_type" />
                    <label for="two" class="mb-0">Webinar</label>
                </div>
              </div>
              <div class="field">
                <input 
                  v-model="newLive.name"
                  class="appearance-none bg-transparent max-w-xl border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
                  type="text" 
                :placeholder="t('pages.lecture.live_name')"
                />
              </div>
              <div class="field">
                <v-select
                  v-if="
                    liveType === 'zoom' 
                    && zoomUsersArray.length > 1
                    && !editZoomUrl"
                  :placeholder="t('pages.lecture.select_zoom')"
                  :options="zoomMergedUsers"
                  @input="selection => updateSelected(selection)"
                  class="mb-2  max-w-xl"
                />
              </div>
              <div class="field">
                <datetime 
                  :placeholder="t('actions.select_date')"
                  type="datetime"
                  v-model="newLive.start_date"
                  class="mb-2 max-w-xl">
                </datetime>
              </div>
              <div class="field">
                <input 
                  v-model.number="newLive.duration"
                  class="appearance-none bg-transparent max-w-xl border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
                  type="number" 
                  :placeholder="t('global.duration_min')" 
                />
              </div>
              <div class="field">
                <button @click="editZoomUrl = !editZoomUrl" class="Btn--Text mb-2">
                  {{t('pages.course.meeting_id_question')}}
                </button>
              <div  v-if="editZoomUrl">
                <p class="mb-1 text-md"> {{t('pages.course.meeting_id_instruction_1')}}</p>
                <p class="mb-2 text-md" v-html="t('pages.course.meeting_id_instruction_2')"></p>
                <input 
                  v-model="newLive.zoom_meeting_id"
                  class="appearance-none bg-transparent max-w-xl border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
                  type="text" 
                  placeholder="Enter your Zoom Meeting ID (i.e. 802212300)" 
                />
              </div>
              </div>
              <p v-if="createError['live']" class="text-danger mb-2">{{t('actions.error')}}</p>
              <button 
                :class="['Btn Btn--Save', {loading: buttonsLoading['live']}]"
                @click="createLecture(newLive, 'live')" >
                {{t('actions.save')}}
              </button>

            </template> 
          </div>

        </div>

        <div v-if="showTeamsSection && showLiveOptions && !addNewLecture && !addNewQuiz && !addNewSection && !showGoogleFormOptions" class="Form--InnerSection mt-6">
          <div :class="['mt-6', {'Form--InnerSection': zoomUsersArray > 0}]">
            <!-- <template v-if="zoomAuthError">
              <div class="border-l-4 border-danger text-danger text-daner-700 px-4 py-1" role="alert">
                <p>{{t('pages.course.zoom_error.one')}}</p>
                <p>{{t('pages.course.zoom_error.two')}} <a target="_blank" href="/admin/academy_settings#/zoom" class="Btn Btn--Text">{{t('global.settings')}}</a></p>
              </div>
            </template>
            <template v-else-if="zoomUsersArray.length == 0">
              <div class=" border-l-4 px-4 py-1" role="alert">
                <p>{{t('pages.course.zoom_missing.one')}}</p>
                <p>{{t('pages.course.zoom_missing.two')}} <a target="_blank" href="/admin/academy_settings#/zoom" class="Btn Btn--Text">{{t('global.settings')}}</a></p>
              </div>
            </template> -->
            <template>
            <label>{{t('pages.course.create_live')}}</label>
              <p v-if="liveType === 'teams'" class="mb-2">
                {{t('pages.course.create_zoom_instruction', [newLive.event_type])}}
              </p>
              <p class="text-danger mt-2" v-if="error != ''">{{error}}</p>
              <div class="field field--inline flex items-center gap-3" v-if="webinar">
                <!-- <div class="flex items-center gap-1">
                  <input type="radio" id="one" value="meeting" v-model="newLive.event_type" />
                    <label for="one" class="mb-0">Meeting</label>
                </div>
                <div class="flex items-center gap-1">
                  <input type="radio" id="two" value="webinar" v-model="newLive.event_type" />
                    <label for="two" class="mb-0">Webinar</label>
                </div> -->
              </div>
              <div class="field">
                <input 
                  v-model="newLive.name"
                  class="appearance-none bg-transparent max-w-xl border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
                  type="text" 
                :placeholder="t('pages.lecture.live_name')"
                />
              </div>
              <div class="field">
                <v-select
                  v-if="
                    liveType === 'teams' 
                    && zoomUsersArray.length > 1
                    && !editTeamsUrl"
                  :placeholder="t('pages.lecture.select_zoom')"
                  :options="zoomMergedUsers"
                  @input="selection => updateSelected(selection)"
                  class="mb-2  max-w-xl"
                />
              </div>
              <div class="field">
                <datetime 
                  :placeholder="t('actions.select_date')"
                  type="datetime"
                  v-model="newLive.start_date"
                  class="mb-2 max-w-xl">
                </datetime>
              </div>
              <div class="field">
                <input 
                  v-model.number="newLive.duration"
                  class="appearance-none bg-transparent max-w-xl border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
                  type="number" 
                  :placeholder="t('global.duration_min')" 
                />
              </div>
              <div class="field">
                <input 
                  v-model="newLive.zoom_meeting_id"
                  class="appearance-none bg-transparent max-w-xl border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
                  type="text" 
                  :placeholder="t('global.teams_meet_link')"
                />
              </div>
              <!-- <div class="field">
                <button @click="editTeamsUrl = !editTeamsUrl" class="Btn--Text mb-2">
                  {{t('pages.course.meeting_id_question')}}
                </button>
                <div  v-if="editTeamsUrl">
                  <p class="mb-1 text-md"> {{t('pages.course.meeting_id_instruction_1')}}</p>
                  <p class="mb-2 text-md" v-html="t('pages.course.meeting_id_instruction_2')"></p>
                  <input 
                    v-model="newLive.zoom_meeting_id"
                    class="appearance-none bg-transparent max-w-xl border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
                    type="text" 
                    placeholder="Enter your Teams Meeting link (https://teams.live.com/meet/9480256849357?p=CHwftAdnXvOsY4B1cL)"
                  />
                </div>
              </div> -->
              <p v-if="createError['live']" class="text-danger mb-2">{{t('actions.error')}}</p>
              <button 
                :class="['Btn Btn--Save', {loading: buttonsLoading['live']}]"
                @click="createLecture(newLive, 'live')" >
                {{t('actions.save')}}
              </button>
              <div class="mt-4 border-l-4 border-warning text-warning text-orange-700 px-4 py-1" role="alert">
                <p>{{t('pages.lecture.teams.hint1')}}</p>
                <p>{{t('pages.lecture.teams.hint2')}}</p>
              </div>
        
            </template> 
          </div>
        </div>
        <div v-if="showGoogleFormOptions && !addNewLecture && !addNewQuiz && !addNewSection && !showTeamsSection && !showLiveOptions" class="mt-3">
            <button 
              :class="{Btn: true, 'Btn--Save_forms': !showTeamsSection, 'Btn--Delete_forms': showTeamsSection}"
              @click="toggleGoogleFormFields">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                <linearGradient id="00wCqH7f0ElurH3hbcIXXa_E4VmOrv6BZqd_gr1" x1="-208.197" x2="-180.197" y1="-150.795" y2="-122.795" gradientTransform="translate(215.243 161.751)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#a235d4"></stop><stop offset="1" stop-color="#831bb3"></stop></linearGradient><path fill="url(#00wCqH7f0ElurH3hbcIXXa_E4VmOrv6BZqd_gr1)" d="M39.004,13.999v27c0,1.105-0.895,2-2,2h-26	c-1.105,0-2-0.895-2-2v-34c0-1.104,0.895-2,2-2h19.002l1.997,7L39.004,13.999z"></path><path fill="#ce97e6" fill-rule="evenodd" d="M30.002,11.999l0.003-7	l8.999,8.999l-7.001,0.001L30.002,11.999z" clip-rule="evenodd"></path><path fill="#fff" fill-rule="evenodd" d="M21.001,21.999v2.001	h10.001v-2.001H21.001z" clip-rule="evenodd"></path><path fill="#fff" fill-rule="evenodd" d="M18.002,21.558	c0.795,0,1.44,0.647,1.44,1.441c0,0.795-0.645,1.441-1.44,1.441c-0.795,0-1.44-0.647-1.44-1.441	C16.562,22.205,17.207,21.558,18.002,21.558z" clip-rule="evenodd"></path><path fill="#fff" fill-rule="evenodd" d="M21.001,26.999v2.001	h10.001v-2.001H21.001z" clip-rule="evenodd"></path><path fill="#fff" fill-rule="evenodd" d="M18.002,26.558	c0.795,0,1.44,0.647,1.44,1.441c0,0.795-0.645,1.441-1.44,1.441c-0.795,0-1.44-0.647-1.44-1.441	C16.562,27.205,17.207,26.558,18.002,26.558z" clip-rule="evenodd"></path><path fill="#fff" fill-rule="evenodd" d="M21.001,31.999v2.001	h10.001v-2.001H21.001z" clip-rule="evenodd"></path><path fill="#fff" fill-rule="evenodd" d="M18.002,31.558	c0.795,0,1.44,0.647,1.44,1.441c0,0.795-0.645,1.441-1.44,1.441c-0.795,0-1.44-0.647-1.44-1.441	C16.562,32.205,17.207,31.558,18.002,31.558z" clip-rule="evenodd"></path><path fill="#ce97e6" fill-rule="evenodd" d="M32.002,9.998	c1.104,0,2.001,0.897,2.001,2.001s-0.897,2.001-2.001,2.001s-2.001-0.897-2.001-2.001S30.898,9.998,32.002,9.998z" clip-rule="evenodd"></path>
                </svg>
              {{ !showTeamsSection ? 'Google Forms' : t('actions.close') + ' Google Forms' }}
            </button>

            <div v-if="showGoogleFormFields">
              <div class="Form--InnerSection mt-3">
                <div class="field mt-5">
                  <label for="google-form-name">{{t('pages.course.create_google-form')}}</label>
                  <input 
                    id="google-form-name"
                    v-model="newForm.name"
                    class="form-control"
                    type="text"
                    :placeholder="t('pages.lecture.google_form.name')"
                  />
                </div>
                <div class="field">
                  <textarea 
                    v-model="newForm.form_id"
                    class="form-control"
                    :placeholder="t('pages.lecture.google_form.placeholder_id')">
                  </textarea>
                </div>
                <button 
                  class="Btn Btn--Save"
                  :class="{loading: buttonsLoading['form']}"
                  @click="createLecture(newForm, 'form')">
                  {{t('actions.save')}}
                </button>
              </div>
            </div>
          </div>
      </div>
      
      <div v-if="course.path && canEdit">
        <h2  class="text-xl mt-6 mb-2">{{t('actions.add_path_courses')}}</h2>  
        <div class="Form Form--Boxed">
          <button class="Btn Btn--Save" @click="selectCourses">{{t('actions.add')}}</button>  
        </div>
      </div>

    <lectureList v-if="!course.path" :course="course" :isLacerbaAdmin="isLacerbaAdmin" />
    
    <contentList :course="course" :academyName="academyName" :isLacerbaAdmin="isLacerbaAdmin" v-else />

     <modal name="upgradeModal">
       <upgradeModal feature="lectures" />
     </modal>

    <modal name="select-courses">
        <div class="v--modal--header p-4">
          <h2 class="text-xl">{{t('pages.course.select_course_path')}}</h2>
        </div>
        <div class="flex flex-1 py-2 px-4">
          <input  v-model="search" type="text" :placeholder="t(`pages.courses.search_undefined`)"  class="flex-1 mr-2" v-on:keyup.enter="getCoursesFromSearch"   />
          <button @click="getCoursesFromSearch" class="Btn Btn--Save">{{t('actions.search')}}</button>
        </div>
        <div class="v--modal--body body--users p-4">
          <div class="mt-2">
            <div v-if="loading" class="LoadingBox mt-4 text-center">
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
            <div v-else>
              <div v-if="allCourses.length > 0">
                <ul class="List--Lined List--Lined--Thin ColoredScrollBar">
                  <li v-for="course in remainingCourses" :key="course.id" class="flex justify-between">
                    {{course.name}}
                    <button class="Btn Btn--Save" @click="addRelated(course.id)">{{t('actions.add')}}</button>
                  </li>
                </ul>
                <paginate
                  v-model="page"
                  :page-count="pagy.pages"
                  :click-handler="pageCallback"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :container-class="'PaginationList'">
                </paginate>
              </div>
              <div v-else class="mt-4 text-center">
                <p>{{t('pages.courses.no_courses')}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="v--modal--actions sm:flex sm:justify-end p-4">
          <button class="Btn Btn--Edit ml-2" @click="$modal.hide('select-courses')">Annulla</button>
        </div>
     </modal>



     <div class="Form--Boxed mt-4 mb-4">
      <h2 class="text-xl  mb-2">{{ t('pages.course.duplicate_course') }}</h2>
      <p class="mb-3">{{ t('pages.course.duplicate_course_info') }}</p>
      <button class="Btn Btn--Save mb-4" @click="duplicateCourse()">
        {{ t('pages.course.duplicate_course') }}
      </button>
      <div v-if="courseDuplicated" class="notification is-success ">
        <p>{{ t('pages.course.duplicate_course_message') }}</p>
      </div>
    </div>
    
  </div>
</template>

<script>

 
import { mapState, mapActions } from 'vuex'
import api from '../../../api'
import lectureList from './lectureList'
import progressBar from '../../commons/progressBar'
import upgradeModal from '../../commons/upgradeModal'
import contentList from './contentList'
import { global as EventBus } from '../../../js/utils/eventBus'; 

export default {
  props: {
    course: Object,
    features: Object,
    liveType: String,
    zoomUsers: Array,
    isLacerbaAdmin: Boolean,
    academyName: String,
    scormEnabled: Boolean
    // courses: Array
  },
  data() {
    return {
      courses: [],
      files: [],
      file: [],
      scormFile: [],
      addNewSection: false,
      addNewLecture: false,
      addNewQuiz: false,
      showLiveOptions: false,
      showZoomSection: false,
      showTeamsSection: false,
      showGoogleFormOptions: false,
      showGoogleFormFields: false,
      buttonsLoading: [],
      createError: [],
      search: '',
      zoomAuthError: false,
      selectedRelated: Number,
      // selectedZoomUser: null,
      newSection: {
        header: true,
        name: ''
      },
      newLecture: {
        header: false,
        name: ''
      },
      newQuiz: {
        name: '',
        repeatable: true,
        min_pass_score: 70
      },
      newLive: {
        name: '',
        start_date: String,
        header: false,
        live: true,
        duration: Number,
        event_type: 'meeting',
        live_vendor: ''
      },
      newForm: {
        name: '',
        form_id: '',
        lecture_type_id: 5,
      },
      newExistingScorm: {
        name: '',
        lecture_type_id: 4,
        external_scorm_id: 0,
        app_id: '',
      },
      error: '',
      page: 1,
      pagy: {},
      loading: false,
      editZoomUrl: false,
      editTeamsUrl: false,
      zoomRealUsers: [],
      scormCourses: [],
      scormSections: {
        addExistingScorm: false
      },
      courseDuplicated: false,

    }
  },
  components: {
    // FileUpload: VueUploadComponent,
    lectureList,
    progressBar,
    upgradeModal,
    contentList,
  },

  computed: {
    ...mapState({
      lectures: state => state.course.lectures,
      allCourses: state => state.course.allCourses
    }),

    canEdit(){
      return !this.course.from_lacerba || !this.course.from_radical_hr || this.isLacerbaAdmin
    },

    remainingCourses() {
      return this.allCourses.filter(el => {
        return !this.course.related_courses.some(elem => elem.id === el.id && elem.name === el.name)
      })
    },

    zoomUsersArray(){
      return this.zoomUsers.sort((a, b) => a.email ).map( user => {
        return {label: user.email, value: user.user_id}
      })
    },

    zoomMergedUsers() {
      let ar = []
      this.zoomUsers.forEach(element => {
        this.zoomRealUsers.forEach(el => {
          if (element.id == el.settingId) {
            element.email = el.email
          }
        })
        ar.push({
          label: element.email,
          value: element.user_id
        })
      });
      return ar
      
    },

    scormCoursesOptions() {
      let ar = []
      this.scormCourses.forEach(element => {
        ar.push({
          label: element.title,
          //value: element.id,
          value: `${element.id}___${element.appId}`
        })
      })
      return ar
    }

  },

  mounted() {
    this.getRelated(this.course.related_courses)
    this.getZoomUsers()
    this.setZoomUserId()

    //TODO: get ID saved in db
    if (this.scormEnabled) this.getSCCourses()

    
    
  },

  methods: {
    
    ...mapActions({
      addLecture: 'addLecture',
      updateRelated: 'updateRelated',
      getRelated: 'getRelated',
      getAllCourses: 'getAllCourses'
    }),

    async addScorm() {
      try {
        const res = await api.ScormCloud.createCourse({
          file: this.file[0]
        })
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },

    async getSCCourses() {
      try {
        const res = await api.ScormCloud.getCourses()
        console.log({res})
        this.scormCourses = res.courses
      } catch (error) {
        console.log(error)
      }
    },
    

    async getZoomUsers() {
      try {
        const res =  await api.Zoom.getUsers()
        this.zoomRealUsers = res
      } catch (error) {
        this.zoomAuthError = true
      }
    },

    setZoomUserId() {
      if (this.zoomUsers.length === 1) {
        this.newLive.zoom_user_id = this.zoomUsers[0].user_id
      }
    },

    selectCourses() {
      this.$modal.show('select-courses');
      this.getCourses('courses');
    },

    getCoursesFromSearch(){
      this.page = 1
      this.getCourses('courses')
    },

    getCourses(type) {
      this.loading = true
      console.log({
        page: this.page,
        type: type,
        search: this.search, 
        all_courses: true
      })
      api.Course.getCourses({
        page: this.page,
        type: type,
        all_courses: true,
        search: this.search
      }).then(res => {
        this.getAllCourses(res.courses)
        this.pagy = res.pagy
        this.loading = false
      })
    },


    pageCallback(pageNum){
      if (pageNum != undefined) {
        this.page = pageNum
      }
      this.getCourses('courses')
    },

    inputFileZip(newFile, oldFile) {
      console.log(newFile)
      this.$refs.uploadZip.active = true
    },

    inputFile(newFile, oldFile) {
      this.$refs.uploadVimeo.active = true
       if (newFile) { 
         console.log('add', newFile)
         if (!newFile.active && newFile.success && newFile.response) {
            let lecture = newFile.response
            if ( !this.lectures.includes(lecture) ) {
              this.addLecture(lecture)   
            }
         }
       }   
    },
    

    async createLecture(lecture, key){
      console.log('Lecture parameter received:', lecture);

      if (this.buttonsLoading[key]) return;
      this.$set(this.buttonsLoading, key, 1)
      if (!this.course.path && this.lectures.length > 0 && this.lectures[0].lectures_limit_reached) {
        this.$modal.show('upgradeModal');
      } else if ( !lecture.live || (lecture.live && lecture.start_date) ) {
        lecture.course_id = this.course.id
        lecture.live_vendor = this.newLive.live_vendor;
        lecture.duration = this.newLive.duration
        lecture.form_name = this.newForm.name;
        lecture.form_id = this.newForm.form_id;
        try {
          console.log('Creating lecture with params:', { lecture });
          const res =  await api.Lecture.create( { lecture: lecture } )
          this.$set(this.buttonsLoading, key, 0)
          if (!res.header) {
            location.href = `lectures/${res.id}`
          } {
            if (!this.course.path) {
              this.addLecture(res)
            } else {
              location.reload() //temp
            }
            
          }
        } catch(err) {
         this.$set(this.buttonsLoading, key, 0)
         this.$set(this.createError, key, 1)
        }
 
      } else {
        this.$set(this.buttonsLoading, key, 0)
        this.error = "Live Date is missing"
      }
    },

    createQuiz(quiz){
      quiz.course_id = this.course.id
      api.Quiz.create( quiz )
      .then((res) => { 
        location.href = `lectures/${res.lecture_id}`
      })
    },

    validatePercentageNumber() {
      if (this.newQuiz.min_pass_score < 0 || this.newQuiz.min_pass_score > 100 ) {
        if (this.newQuiz.toString().length > 3 ) {
          this.newQuiz  .min_pass_score = Math.floor( this.newQuiz.min_pass_score / 10)
        }
      }
    },

    addRelated(courseId) {
      const target = event.currentTarget ;
      EventBus.emit('loader:local:start', { elem: target })
      api.Course.createRelated({
        ...this.course, 
        related: courseId
      }).then((res) => {
        EventBus.emit('loader:local:end', { elem: target })
        this.course = res
        this.updateRelated(res.related_courses)
        this.$modal.hide('select-courses');
        setTimeout( e => {
          window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })   
        }, 300)
      }).catch(err => {
         EventBus.emit('loader:local:end', { elem: target })
      })  
    },

    updateSelected (selection) {
      // this.selectedZoomUser = selection.value;
      this.newLive.zoom_user_id = selection.value
    },

    toggleScormSection(sectionId) {
      console.log(sectionId);
      this.scormSections[sectionId] = !this.scormSections[sectionId]
    },

    updateSelectedScorm (selection) {
      let value = selection.value
      let values = value.split('___')
      this.newExistingScorm.external_scorm_id = values[0]
      const scormCourse = this.scormCourses.find((sc) => sc.id == this.newExistingScorm.external_scorm_id)
      this.newExistingScorm.name = scormCourse.title;
      this.newExistingScorm.app_id = values[1]

      console.log(this.newExistingScorm);
    },

    duplicateCourse() {
      api.Course.duplicate(this.course.id)
      .then(() => {
        this.courseDuplicated = true;
      })
      .catch(error => {
        console.error('Errore durante la duplicazione del corso:', error);
      });
    },
    
    toggleGoogleFormOptions() {
      this.showGoogleFormOptions = !this.showGoogleFormOptions;
      if (this.showGoogleFormOptions) {
        this.showLiveOptions = false;
        this.addNewLecture = false;
        this.addNewSection = false;
        this.addNewQuiz = false;
      }
    },

    toggleGoogleFormFields() {
      this.showGoogleFormFields = !this.showGoogleFormFields;
    },

    toggleLiveOptions() {
      this.showLiveOptions = !this.showLiveOptions;
      if (this.showLiveOptions) {
        this.newLive.live_vendor = this.liveType;
      } else {
        this.newLive.live_vendor = '';
      }
      this.showZoomSection = false;
      this.showTeamsSection = false;
      this.addNewLecture = false;
      this.addNewSection = false;
      this.addNewQuiz = false;
      this.showGoogleFormOptions = false;
      },


    toggleZoomSection() {
      this.showZoomSection = !this.showZoomSection;
      if (this.showZoomSection) {
        this.newLive.live_vendor = 'zoom';
      }
      this.showTeamsSection = false;
      },


    toggleTeamsSection() {
      this.showTeamsSection = !this.showTeamsSection;
      if (this.showTeamsSection) {
        this.newLive.live_vendor = 'teams';
      }
      this.showZoomSection = false;
      },
  }
  
}
</script>