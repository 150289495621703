<template>
  <div>
    <div class="Form--Boxed mb-8">
      <div class="grid sm:grid-cols-2 col-gap-3">
        <div 
          v-for="userField in userFields" 
          :key="userField.id" 
          class="field">
          <label :for="`userField_${userField.id}`">
            {{ t(`user_field.${userField.name}`) + `${ userField.mandatory ? ' *' : '' }` }}
          </label>
          <input 
            :id="`userField_${userField.id}`"
            v-model="userField.value"
            type="text"
            :disabled="!userField.editable && currentUser.admin_role != 'superAdmin'"
            @keydown="nameKeydown($event)"
            :placeholder="t(`user_field.${userField.name}`)" />
        </div>
      </div>
      
      <p v-for="(error, index) in errors" :key="index" class="text-danger mb-2">
        {{error}}
      </p>

        <button 
          class="Btn Btn--Save"
          @click="updateUserFields">
          {{ t(`actions.${cta}`) }}
        </button>
    </div>
    <div class="Form--Boxed mb-8" v-if="userSettings.length > 0"> 
      <div v-for="userSetting in userSettings" :key="userSetting.id" class="field">
        <label :for="`userSetting_${userSetting.id}`">
          {{ t(`user_setting.${userSetting.name}`) }}
        </label>
        <select 
          :id="`userSetting_${userSetting.id}`" 
          class="user-type-select"
          v-model="userSetting.value"
          :disabled="!userSetting.editable && currentUser.admin_role != 'superAdmin'"
          @change="nameKeydown($event)">
          <option :value="null">Default</option>
          <option v-for="option in userSettingOptions(userSetting.name)" :key="option.id" :value="option.code">
            {{ option.name }}
          </option>
        </select>
      </div>
      <p v-for="(error, index) in errors" :key="index" class="text-danger mb-2">
        {{ error }}
      </p>

      <button class="Btn Btn--Save" @click="updateUserSettings">
        {{ t(`actions.${cta}`) }}
      </button>
    </div>
    
    <div class="Form--Boxed mb-4" v-if="currentUser.profileImage">
      <uploadUserFieldImage :userField="currentUser.profileImage"  />
    </div>


    <div class="Form--Boxed mb-8" v-if="userFieldsCoach && userFieldsCoach.length > 0">

      <p v-for="(error, index) in errors" :key="index">
        {{error}}
      </p>
      <div 
        v-for="userField in userFieldsCoach" 
        :key="userField.id" 
        class="field">
        <label :for="`userField_${userField.id}`">
          {{ t(`user_field.${userField.name}`) + `${ userField.mandatory ? ' *' : '' }` }}
        </label>

        <input 
          v-if="userField.name != 'description'"
          :id="`userField_${userField.id}`"
          v-model="userField.value" 
          type="text"
          :placeholder="t(`user_field.${userField.name}`)" />
        
        <textarea 
          v-else-if="userField.name === 'description'"
          :id="`userField_${userField.id}`"
          v-model="userField.value" 
          type="text"
          :placeholder="t(`user_field.${userField.name}`)" />
      </div>

        <button 
          class="Btn Btn--Save"
          @click="updateCoachingUserFields">
          {{ t(`actions.save`) }}
        </button>
        
    </div>

  </div>
</template>

<script>
import api from '../../api'
import uploadUserFieldImage from '../commons/uploadUserFieldImage'
import { global as EventBus } from '../../js/utils/eventBus'; 


export default {
  props: {
    currentUser: Object,
    languages: Array
  },
  data() {
    return {
      errors: [],
      userFields: [],
      userFieldsCoach: [],
      specialCharErr: "",
      cta: "save",
      disabled: true,
      userSettings: []
    }
  },
  components: {
    uploadUserFieldImage
  },
  computed: {
    // mandatoryUserFields(){
    //   return this.currentUser.userFields.filter( field => {
    //     return field.mandatory
    //   })
    // }
  },
  mounted() {
    this.userFieldsCoach = this.currentUser.userFieldsCoach
    this.userFields = this.currentUser.user_fields.filter( field => {
      return !field.hidden
    })
    this.userSettings = this.currentUser.user_settings.filter( setting => {
      return !setting.hidden
    })
  },
  methods: {

    userSettingOptions(setting) {
      switch (setting) {
        case 'dark_mode':
          return [
            { id: 1, name: 'Light', code: 'false' },
            { id: 0, name: 'Dark', code: 'true' }
          ];
        case 'lang':
          return this.languages.map(language => {
            return {
              id: language.id,
              code: language.code,
              name: language.code
            }
          });
        default:
          return [];
      }

    },

    nameKeydown(e) {
      //if (/^\W$/.test(e.key)) {
        //e.preventDefault();
      //}
    },

    updateUserFields() {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target });
      let promises = this.userFields.map(userField => this.updateUserField(userField));
      
      Promise.all(promises).then(() => {
          console.log('All fields updated successfully!');
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target });
      }).catch(err => {
          console.error('There was an error updating the fields:', err);
          this.$toastr.e(err);
          EventBus.emit('loader:local:end', { elem: target });
      });
  },
    updateUserSettings(){
      const target = event.currentTarget ;
      EventBus.emit('loader:local:start', { elem: target })
      let promises = this.userSettings.map(userSetting => this.updateUserSetting(userSetting));
      
      Promise.all(promises).then(() => {
          console.log('All settings updated successfully!');
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target });
          location.reload();
      }).catch(err => {
          console.error('There was an error updating the settings:', err);
          this.$toastr.e(err);
          EventBus.emit('loader:local:end', { elem: target });
      });
    },
    updateUserSetting(userSetting){
     
        return new Promise((resolve, reject) => {
            api.UserSetting.update(userSetting)
            .then(res => {
                if (res.status === 'error') {
                    let error = `${userSetting.name.replace(/_/g, ' ')} cannot have special characters`;
                    this.errors.push(error);
                    reject(error);
                } else {
                    resolve();
                }
            }).catch(err => {
                reject(err);
            });
        });

    
    },
    updateCoachingUserFields(){
      let self = this
      console.log('updateCoachingUserFields')
      self.errors = []
      self.userFieldsCoach.map( userField => {
        console.log(userField.value)
        self.updateUserField(userField)
      })
    },
    updateUserField(userField) {
      return new Promise((resolve, reject) => {
        if ((userField.mandatory && userField.value != "") || !userField.mandatory) {
            api.UserField.update(userField)
            .then(res => {
                if (res.status === 'error') {
                    let error = `${userField.name.replace(/_/g, ' ')} cannot have special characters`;
                    this.errors.push(error);
                    reject(error);
                } else {
                    resolve();
                }
            }).catch(err => {
                reject(err);
            });
        } else {
            let error = `${userField.name.replace(/_/g, ' ')} can't be blank`;
            this.errors.push(error);
            reject(error);
        }
    });
  },

  }
}
</script>